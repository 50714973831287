@import '../../../assets/styles/index';

.sap-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__wrapper {
    display: flex;
    margin-left: 5%;
    border: 1px solid $troy-dark-gray;
    background: $common-white;
    font-style: italic;
    margin-bottom: 2px !important;
    width: 150px !important;
    height: 25px !important;
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    justify-content: center;

    &-icon {
      font-family: 'SAP-Icons' !important;
      color: $sap-icon-color !important;

      &_search {
        color: $troy-dark-gray !important;
      }

      &_clear {
        color: $troy-dark-gray !important;
        transition: 0.4s !important;
        cursor: pointer;

        &:hover {
          color: $troy-black !important;
        }
      }
    }

    &_focus {
      font-family: '72-Light' !important;
      font-size: 0.8125rem !important;
    }

    &-fieldset {
      border: none !important;
      font-family: '72-Light' !important;
      font-size: 0.8125rem !important;
    }
  }
}
