@import '../variables/colors';

@mixin table {
  display: table;
}

@mixin table-row {
  display: table-row;
}

@mixin table-cell {
  display: table-cell;
}

@mixin troy-button {
  background-color: $troy-turquoise !important;
  color: $troy-white !important;
  font-weight: bolder !important;
}
