@import '../../../assets/styles/index';

.filter {
  font-family: '72-Light' !important;

  &__form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 7px;

    &-sticy {
      margin-top: 0px;
    }

    &-select-button {
      display: inline-flex;
      font-family: 'SAP-Icons' !important;
      color: $sap-icon-color !important;
      border: none;
      position: absolute;
      right: 0;
      pointer-events: none;
      background: transparent;
    }

    &-type {
      display: flex;
      width: 150px !important;
      height: 25px !important;
      justify-content: flex-end;
      flex-direction: column;

      &-text {
        color: $common-black;
      }

      &-select {
        display: inline-flex;
        font-family: 'SAP-Icons' !important;
        border: 1px solid $troy-dark-gray;
        background: $common-white;
        height: 25px !important;
        font-size: 0.8125rem !important;

        &::before,
        &::after {
          border-bottom: 0 !important;
        }

        &-root {
          color: $sap-darker-gray;
          font-family: '72-Light' !important;
          font-size: 0.8125rem !important;
          font-style: italic;
        }

        &-input {
          padding-right: 24px !important;
          background: none;
          font-family: '72-Light' !important;
          font-size: 0.8125rem !important;
          background: none !important;
        }

        &-menu-item {
          font-family: '72-Light' !important;
          font-size: 0.8125rem !important;
        }
      }
    }

    &-channel {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 150px !important;
      height: 25px !important;

      &-text {
        color: $common-black;
      }

      &-select {
        font-size: 0.8125rem !important;
        font-family: 'SAP-Icons' !important;
        border: 1px solid $troy-dark-gray;
        background: $common-white;
        height: 25px !important;

        &::before,
        &::after {
          border-bottom: 0 !important;
        }

        &-root {
          color: $sap-darker-gray;
          font-family: '72-Light' !important;
          font-size: 0.8125rem !important;
          font-style: italic;
        }

        &-input {
          color: black;
          padding-right: 24px !important;
          background: none !important;
          font-family: '72-Light' !important;
        }

        &-menu-item {
          font-family: '72-Light' !important;
          font-size: 0.8125rem !important;
          background-color: none !important;
        }
      }
    }

    &-date-picker {
      display: inline-flex;
      justify-content: flex-end;
      flex-direction: column;
      height: 25px !important;
      z-index: 3;
      width: 191px !important;

      &-text {
        color: $common-black;
      }

      &-input {
        border: 1px solid $troy-dark-gray;
        height: 25px !important;
        width: 190px !important;
        display: inline-flex !important;
      }
    }

    &-filter-button {
      min-width: 34px !important;
      min-height: 26px !important;
      width: 34px !important;
      height: 26px !important;
      color: $troy-white !important;
      background-color: $sap-button-color !important;
      border-radius: 6px !important;
      border-color: $sap-button-border-color !important;
      padding: 0px !important;
    }

    &-filter-button-wrapper {
      display: flex !important;
    }

    &-filter-counter-wrapper {
      display: flex;
      color: $sap-icon-color;
    }
  }
}
