@import '../../../assets/styles/index';

i {
  font-style: normal !important;
}

.postbox {
  &__wrapper {
    font-style: normal !important;
  }

  &__notification {
    margin-top: 20px;
  }

  &__menu {
    font-family: '72-Light' !important;
    display: flex;
    height: 100px;
    flex-direction: row;
    justify-content: space-around;
    background: $sap-global-light !important;
    position: relative;
    visibility: visible;

    &-sticky {
      @extend .postbox__menu;

      @include md-down {
        position: relative;
        padding-top: 0px;
        width: 100%;
        height: 100px;
      }

      &-show {
        @extend .postbox__menu-sticky;
        position: fixed;
        width: 67%;
        z-index: 99;
        padding-top: 5px;
        margin-top: 20px;

        @include md-down {
          position: relative;
          padding-top: 0px;
          width: 100%;
          height: 100px;
          margin-top: 0px;
        }
      }

      &-hidden {
        @extend .postbox__menu-sticky;

        &-with-scroll {
          height: 20px;
          visibility: hidden;

          @include md-down {
            height: 0px;
          }
        }

        &-without-scroll {
          height: 0px;
          visibility: hidden;
        }
      }
    }
  }

  &__button-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    &-sticky {
      @extend .postbox__button-container;

      @include md-down {
        padding-top: 0px;
      }

      padding-top: 125px;
      z-index: 100;
    }
  }

  &__button-wrapper {
    display: flex;
    position: absolute;

    &-sticky {
      @extend .postbox__button-wrapper;
      margin-top: 0px;
      position: fixed;
      z-index: 100;

      @include md-down {
        position: absolute;
      }
    }

    &-open {
      font-family: 'SAP-Icons' !important;
      min-width: 32px !important;
      min-height: 20px !important;
      width: 32px !important;
      height: 20px !important;
      padding: 0 !important;
      border: none;
      background-color: $sap-divider-color !important;
      color: $sap-icon-color !important;
      margin-right: 8px !important;

      &-active {
        @extend .postbox__button-wrapper-open;

        color: $common-white !important;
        background-color: $sap-icon-color !important;
      }
    }

    &-pin {
      font-family: 'SAP-Icons' !important;
      min-width: 32px !important;
      min-height: 20px !important;
      width: 32px !important;
      height: 20px !important;
      padding: 0 !important;
      border: none;
      background-color: $sap-divider-color !important;
      color: $sap-icon-color !important;

      &-active {
        @extend .postbox__button-wrapper-pin;

        color: $common-white !important;
        background-color: $sap-icon-color !important;
        height: 21px !important;
      }
    }
  }

  &__divider-horizontal {
    display: block;
    border: 0;
    border-top: 1px solid $sap-divider-color;
    padding: 0;
    width: 100%;

    &-sticky {
      @extend .postbox__divider-horizontal;

      @include md-down {
        position: relative;
        margin-top: 0px;
        width: 100%;
      }

      width: 67%;
      position: fixed;
      z-index: 98;
    }
  }
}
