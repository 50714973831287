@import '../../../assets/styles/index';

$content-padding: 20px;
$mobile-content-padding: 5px;

.demand-table {
  font-family: '72-Light' !important;
  font-size: 0.8125rem !important;

  &__wrapper {
    display: flex;
    margin: 15px 15px 25px 15px;
    flex-direction: column;
    color: $common-black;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $content-padding;

    &-header {
      font-weight: bolder;
      text-align: left;
      cursor: default;
    }

    &-pic {
      max-height: 100px;
      max-width: 100px;
      text-align: right;
      position: relative;
      top: 0;
      right: -5px;

      @include md-up {
        right: -$content-padding;
      }
    }
  }

  &__blur {
    &-button {
      @include troy-button;
    }
  }

  &__table {
    background: $troy-white;
    width: 100%;
    font-size: 0.8rem;
    border-collapse: collapse;
    overflow: hidden;
    height: inherit;

    cursor: default;
    @include table;

    &-wrapper {
      box-sizing: border-box;
      max-height: 450px;
      overflow-y: scroll;
      padding: 5px;
    }

    &-row {
      @include table-row;
      border: 1px solid $troy-dark-gray;
    }

    &-cell {
      padding: 1px;
      border: 1px solid $troy-dark-gray;
      padding: 5px;

      @include table-cell;
    }

    &-cell-value {
      font-weight: bolder;
      padding: 5px;

      @include table-cell;
    }

    &-header {
      background: $troy-gray;
      font-weight: bolder;
    }
  }

  &__summary {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-weight: bolder;

    &-wrapper {
      height: 26px;
      width: 100%;
      display: flex;
      background: $troy-white;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
    }

    &-header {
    }

    &-value {
      font-weight: bolder;
      cursor: default;
    }
  }

  &__extended {
    display: flex;

    @include md-up {
      flex-direction: column;
    }
  }

  &__document {
    &-wrapper {
      color: $common-black;
      margin-top: 10px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: calc(50% - 15px);
      flex: 1;
      font-size: 0.8rem;

      @include mobile {
        text-align: center;
      }
      @include desktop {
        text-align: left;
      }

      &_mobile-only {
        @include only-mobile;
        @extend .demand-table__document-wrapper;
      }
    }

    &-wrapper:nth-child(2) {
      margin-left: 10px;

      @include md-up {
        margin-left: 0px;
      }
    }

    &-bottom-container {
      display: flex;
      background: $troy-white;
      width: 100%;
      justify-content: space-between;
    }

    &-title-wrapper {
      display: flex;
    }

    &-subtitle-wrapper {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }

    &-title {
      font-weight: bolder;
      word-break: break-word;
      cursor: default;
      font-size: 1rem;
    }

    &-subtitle {
      word-break: break-word;
      cursor: default;
    }

    &-link-wrapper {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    &-link {
      display: flex;
      margin: 8px 0;
      text-align: center;
      text-decoration: none;
    }

    &-button {
      align-self: center;
      color: $sap-active-color !important;
    }
  }
}
