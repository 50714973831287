@import '../../../assets/styles/index';

$main-text-color: $troy-dark-gray;
$main-margin: 10px;

.notification {
  &__wrapper {
    position: relative;
    margin: 0 5px;
    padding: 0 20px;
    max-height: 200px !important;
    font-family: '72-Light' !important;
    box-shadow: 0;

    &_notification {
      position: relative;
      padding: 0 20px;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 2px;
        background-color: $main-text-color;
      }
    }

    &_to_customer,
    &_to_customer_from_lawyer,
    &_to_customer_from_client,
    &_from_customer {
      display: flex;
      justify-content: flex-start;
      padding: 0 20px;
    }

    &_from_customer {
      justify-content: flex-end;
    }
  }

  &__container {
    &_to_customer,
    &_to_customer_from_lawyer,
    &_to_customer_from_client,
    &_from_customer {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      align-items: center;
      margin: 6px 0;
      max-width: 70%;
      color: $main-text-color;
      padding: $main-margin;
      border: 1px solid $common-dark-gray !important;
      border-radius: 0 !important;
      box-shadow: none !important;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 30px;
        left: -2px;
        transform: translate(-50%, -50%) rotateZ(-45deg) translateY(1px)
          translateZ(0);
        width: 10px;
        height: 10px;
        background-color: $troy-white;
        border-top: 1px solid $common-dark-gray;
        border-left: 1px solid $common-dark-gray;
      }

      &-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        &-left-wrapper,
        &-right-wrapper {
          display: flex;
        }

        &-image {
          font-family: 'SAP-Icons' !important;
          font-size: 32px;
          display: flex;
          width: 50px;
          height: 50px;
          justify-content: center;
          align-items: center;
        }

        &-subject {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          &-date {
            color: $common-dark-gray;
          }

          &-name {
            color: $sap-darkest-gray;
          }
        }

        &-logo {
          font-family: 'SAP-Icons' !important;
          display: flex;
          width: 50px;
          height: 50px;
          margin-left: 20px;
          margin-right: 5px;
          justify-content: center;
          align-items: center;
          font-size: 32px;
          color: $sap-active-color !important;

          & a {
            text-decoration: none !important;
            color: $sap-active-color !important;
          }
        }
      }
    }

    &_from_customer {
      border: 2px solid $common-dark-gray;

      &::before {
        left: calc(100% + 2px);
        transform: translate(-50%, -50%) rotateZ(135deg) translateY(1px)
          translateZ(0);
        border-top: 1px solid $common-dark-gray;
        border-left: 1px solid $common-dark-gray;
      }
    }

    &_to_customer_from_lawyer {
      border: 2px solid $common-dark-gray;

      &::before {
        border-top: 1px solid $common-dark-gray;
        border-left: 1px solid $common-dark-gray;
      }
    }

    &_to_customer_from_client {
      border: 2px solid $common-dark-gray;

      &::before {
        border-top: 1px solid $common-dark-gray;
        border-left: 1px solid $common-dark-gray;
      }
    }

    &_notification {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 6px 0;
      padding: 10px;
      border: 1px solid $common-dark-gray;
    }
  }

  &__date {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $main-text-color;

    &-info {
      color: $main-text-color;
    }
  }

  &__body {
    &_to_customer,
    &_to_customer_from_lawyer,
    &_to_customer_from_client,
    &_from_customer,
    &_notification {
      position: relative;
      justify-content: center;
      padding-top: 10px;
      color: $sap-darkest-gray;
      word-break: break-word;
      white-space: pre-line;
    }

    &_notification {
      color: $troy-dark-gray;
    }

    &-download {
      & a {
        text-decoration: none !important;
        color: $sap-active-color !important;
      }
    }
  }

  &__attachment {
    margin: 0;
    @include sm-down {
      margin-top: 8px;
    }
  }

  &__show-more {
    margin-top: 6px;
    color: $sap-active-color;
    text-decoration: none;
    text-align: right;
    font-weight: bold;
    cursor: pointer;
  }
}

.download-ref {
  &__icon {
    display: block;
    margin: 0 6px;
    height: 50px;
  }
}
