@import '../../../assets/styles/index';
@import '../../../assets/styles/mixins/screens';

@mixin input-field() {
  height: 22px !important;
  padding: 0 !important;
  padding-left: 8px !important;
  border-radius: 4px !important;
  background-color: white !important;
  font-size: 0.9rem !important;
}

$calculator-height: 197px;
.recommend-help-calculator {
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    color: $common-white;
    height: auto;
    margin-left: 0;
    margin-top: 10px;
    padding-bottom: 0;
    @include troy-background-login;
    cursor: default;

    @include md-up {
      height: auto;
    }

    @include desktop {
      padding: 10px 30px;
    }

    @include mobile {
      padding: 5px 15px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__calculator {
    position: relative;

    &-block {
    }
  }

  &__remark {
    padding: 10px 30px;
    padding-bottom: 20px;
    width: 100%;
    text-align: left;
    font-size: 0.6rem;

    @include sm-up {
      font-size: 0.7rem;
    }

    &-wrapper {
      color: $common-dark-gray;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    min-height: 42px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    @include desktop {
      align-items: center;
    }

    @include md-up {
      width: 360px;
    }
  }

  &__label {
    width: 90px;
    font-size: 0.9rem;
  }

  &__past-label {
    width: 140px;
    font-size: 0.9rem;
  }

  &__select {
    height: 22px;
    margin: 10px;
    background: $common-white;
    border-radius: 4px;
    font-size: 0.8rem;

    &-root {
      color: black;
      padding-left: 6px;
    }

    &-input {
      color: black;
      padding-right: 24px !important;
    }
  }

  &__input {
    &-field {
      @include input-field();

      &-red {
        @include input-field();
        background: rgb(255, 81, 81) !important;
      }
    }

    &-root,
    &-error {
      @include desktop {
        margin-left: 10px !important;
      }
    }

    &-wrapper {
      flex-grow: 1 !important;
      @include mobile {
        width: 100%;
      }
    }

    &-error {
      &-wrapper {
        @extend .recommend-help-calculator__input-wrapper;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__datepicker {
    &-wrapper {
      width: 100%;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-description {
      font-size: 1rem;
    }

    &-input {
      position: relative !important;
      left: 16px;
      color: $troy-white !important;
    }
  }

  &__text-area {
    margin-top: 10px;
    padding: 6px 10px;
    font-size: 0.8rem;
    color: $troy-black;
  }

  &__button {
    margin: 10px !important;
    @include troy-button;

    &:disabled {
      background-color: $troy-dark-gray !important;
    }
  }
}
