/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'SAP-icons';
  content: attr(data-icon);
  speak: none;
}
/* Use the following CSS code if you want to have a class per icon */
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'SAP-icons';
  font-style: normal;
  speak: none;
}

.icon-accidental-leave:before {
  content: '\e000';
}
.icon-account:before {
  content: '\e001';
}
.icon-wrench:before {
  content: '\e002';
}
.icon-windows-doors:before {
  content: '\e003';
}
.icon-washing-machine:before {
  content: '\e004';
}
.icon-visits:before {
  content: '\e005';
}
.icon-video:before {
  content: '\e006';
}
.icon-travel-expense:before {
  content: '\e007';
}
.icon-temperature:before {
  content: '\e008';
}
.icon-task:before {
  content: '\e009';
}
.icon-synchronize:before {
  content: '\e00a';
}
.icon-survey:before {
  content: '\e00b';
}
.icon-settings:before {
  content: '\e00c';
}
.icon-search:before {
  content: '\e00d';
}
.icon-sales-document:before {
  content: '\e00e';
}
.icon-retail-store:before {
  content: '\e00f';
}
.icon-refresh:before {
  content: '\e010';
}
.icon-product:before {
  content: '\e011';
}
.icon-present:before {
  content: '\e012';
}
.icon-ppt-attachment:before {
  content: '\e013';
}
.icon-pool:before {
  content: '\e014';
}
.icon-pie-chart:before {
  content: '\e015';
}
.icon-picture:before {
  content: '\e016';
}
.icon-photo-voltaic:before {
  content: '\e017';
}
.icon-phone:before {
  content: '\e018';
}
.icon-pending:before {
  content: '\e019';
}
.icon-pdf-attachment:before {
  content: '\e01a';
}
.icon-past:before {
  content: '\e01b';
}
.icon-outgoing-call:before {
  content: '\e01c';
}
.icon-opportunity:before {
  content: '\e01d';
}
.icon-opportunities:before {
  content: '\e01e';
}
.icon-notes:before {
  content: '\e01f';
}
.icon-money-bills:before {
  content: '\e020';
}
.icon-map:before {
  content: '\e021';
}
.icon-log:before {
  content: '\e022';
}
.icon-line-charts:before {
  content: '\e023';
}
.icon-lightbulb:before {
  content: '\e024';
}
.icon-leads:before {
  content: '\e025';
}
.icon-lead:before {
  content: '\e026';
}
.icon-laptop:before {
  content: '\e027';
}
.icon-kpi-managing-my-area:before {
  content: '\e028';
}
.icon-kpi-corporate-performance:before {
  content: '\e029';
}
.icon-incoming-call:before {
  content: '\e02a';
}
.icon-inbox:before {
  content: '\e02b';
}
.icon-horizontal-bar-chart:before {
  content: '\e02c';
}
.icon-history:before {
  content: '\e02d';
}
.icon-heating-cooling:before {
  content: '\e02e';
}
.icon-gantt-bars:before {
  content: '\e02f';
}
.icon-future:before {
  content: '\e030';
}
.icon-fridge:before {
  content: '\e031';
}
.icon-fallback:before {
  content: '\e032';
}
.icon-expense-report:before {
  content: '\e033';
}
.icon-excel-attachment:before {
  content: '\e034';
}
.icon-energy-saving-lightbulb:before {
  content: '\e035';
}
.icon-employee:before {
  content: '\e036';
}
.icon-email:before {
  content: '\e037';
}
.icon-edit:before {
  content: '\e038';
}
.icon-duplicate:before {
  content: '\e039';
}
.icon-download:before {
  content: '\e03a';
}
.icon-doc-attachment:before {
  content: '\e03b';
}
.icon-dishwasher:before {
  content: '\e03c';
}
.icon-delete:before {
  content: '\e03d';
}
.icon-decline:before {
  content: '\e03e';
}
.icon-complete:before {
  content: '\e03f';
}
.icon-competitor:before {
  content: '\e040';
}
.icon-collections-management:before {
  content: '\e041';
}
.icon-chalkboard:before {
  content: '\e042';
}
.icon-cart:before {
  content: '\e043';
}
.icon-card:before {
  content: '\e044';
}
.icon-camera:before {
  content: '\e045';
}
.icon-calendar:before {
  content: '\e046';
}
.icon-begin:before {
  content: '\e047';
}
.icon-basket:before {
  content: '\e048';
}
.icon-bar-chart:before {
  content: '\e049';
}
.icon-attachment:before {
  content: '\e04a';
}
.icon-arrow-top:before {
  content: '\e04b';
}
.icon-arrow-right:before {
  content: '\e04c';
}
.icon-arrow-left:before {
  content: '\e04d';
}
.icon-arrow-bottom:before {
  content: '\e04e';
}
.icon-approvals:before {
  content: '\e04f';
}
.icon-appointment:before {
  content: '\e050';
}
.icon-alphabetical-order:before {
  content: '\e051';
}
.icon-along-stacked-chart:before {
  content: '\e052';
}
.icon-alert:before {
  content: '\e053';
}
.icon-addresses:before {
  content: '\e054';
}
.icon-address-book:before {
  content: '\e055';
}
.icon-add-filter:before {
  content: '\e056';
}
.icon-add-favorite:before {
  content: '\e057';
}
.icon-add:before {
  content: '\e058';
}
.icon-activities:before {
  content: '\e059';
}
.icon-action:before {
  content: '\e05a';
}
.icon-accept:before {
  content: '\e05b';
}
.icon-hint:before {
  content: '\e05c';
}
.icon-group:before {
  content: '\e05d';
}
.icon-check-availability:before {
  content: '\e05e';
}
.icon-weather-proofing:before {
  content: '\e05f';
}
.icon-payment-approval:before {
  content: '\e060';
}
.icon-batch-payments:before {
  content: '\e061';
}
.icon-bed:before {
  content: '\e062';
}
.icon-arobase:before {
  content: '\e063';
}
.icon-family-care:before {
  content: '\e064';
}
.icon-favorite:before {
  content: '\e065';
}
.icon-navigation-right-arrow:before {
  content: '\e066';
}
.icon-navigation-left-arrow:before {
  content: '\e067';
}
.icon-e-care:before {
  content: '\e068';
}
.icon-less:before {
  content: '\e069';
}
.icon-lateness:before {
  content: '\e06a';
}
.icon-lab:before {
  content: '\e06b';
}
.icon-internet-browser:before {
  content: '\e06c';
}
.icon-instance:before {
  content: '\e06d';
}
.icon-inspection:before {
  content: '\e06e';
}
.icon-image-viewer:before {
  content: '\e06f';
}
.icon-home:before {
  content: '\e070';
}
.icon-grid:before {
  content: '\e071';
}
.icon-goalseek:before {
  content: '\e072';
}
.icon-general-leave-request:before {
  content: '\e073';
}
.icon-create-leave-request:before {
  content: '\e074';
}
.icon-flight:before {
  content: '\e075';
}
.icon-filter:before {
  content: '\e076';
}
.icon-favorite-list:before {
  content: '\e077';
}
.icon-factory:before {
  content: '\e078';
}
.icon-endoscopy:before {
  content: '\e079';
}
.icon-employee-pane:before {
  content: '\e07a';
}
.icon-employee-approvals:before {
  content: '\e07b';
}
.icon-email-read:before {
  content: '\e07c';
}
.icon-electrocardiogram:before {
  content: '\e07d';
}
.icon-documents:before {
  content: '\e07e';
}
.icon-decision:before {
  content: '\e07f';
}
.icon-database:before {
  content: '\e080';
}
.icon-customer-history:before {
  content: '\e081';
}
.icon-customer:before {
  content: '\e082';
}
.icon-credit-card:before {
  content: '\e083';
}
.icon-create-entry-time:before {
  content: '\e084';
}
.icon-contacts:before {
  content: '\e085';
}
.icon-compare:before {
  content: '\e086';
}
.icon-clinical-order:before {
  content: '\e087';
}
.icon-chain-link:before {
  content: '\e088';
}
.icon-pull-down:before {
  content: '\e089';
}
.icon-cargo-train:before {
  content: '\e08a';
}
.icon-car-rental:before {
  content: '\e08b';
}
.icon-business-card:before {
  content: '\e08c';
}
.icon-bar-code:before {
  content: '\e08d';
}
.icon-folder-blank:before {
  content: '\e08e';
}
.icon-passenger-train:before {
  content: '\e08f';
}
.icon-question-mark:before {
  content: '\e090';
}
.icon-world:before {
  content: '\e091';
}
.icon-iphone:before {
  content: '\e092';
}
.icon-ipad:before {
  content: '\e093';
}
.icon-warning:before {
  content: '\e094';
}
.icon-sort:before {
  content: '\e095';
}
.icon-course-book:before {
  content: '\e096';
}
.icon-course-program:before {
  content: '\e097';
}
.icon-add-coursebook:before {
  content: '\e098';
}
.icon-print:before {
  content: '\e099';
}
.icon-save:before {
  content: '\e09a';
}
.icon-play:before {
  content: '\e09b';
}
.icon-pause:before {
  content: '\e09c';
}
.icon-record:before {
  content: '\e09d';
}
.icon-response:before {
  content: '\e09e';
}
.icon-pushpin-on:before {
  content: '\e09f';
}
.icon-pushpin-off:before {
  content: '\e0a0';
}
.icon-unfavorite:before {
  content: '\e0a1';
}
.icon-learning-assistant:before {
  content: '\e0a2';
}
.icon-timesheet:before {
  content: '\e0a3';
}
.icon-time-entry-request:before {
  content: '\e0a4';
}
.icon-list:before {
  content: '\e0a5';
}
.icon-action-settings:before {
  content: '\e0a6';
}
.icon-share:before {
  content: '\e0a7';
}
.icon-feed:before {
  content: '\e0a8';
}
.icon-role:before {
  content: '\e0a9';
}
.icon-flag:before {
  content: '\e0aa';
}
.icon-post:before {
  content: '\e0ab';
}
.icon-inspect:before {
  content: '\e0ac';
}
.icon-inspect-down:before {
  content: '\e0ad';
}
.icon-appointment-2:before {
  content: '\e0ae';
}
.icon-target-group:before {
  content: '\e0af';
}
.icon-marketing-campaign:before {
  content: '\e0b0';
}

/* duplicate icon with alternative class name for compatibility reasons */
.icon-message-error:before {
  content: '\e0b1';
}
.icon-notification:before {
  content: '\e0b1';
}

.icon-comment:before {
  content: '\e0b2';
}
.icon-shipping-status:before {
  content: '\e0b3';
}
.icon-collaborate:before {
  content: '\e0b4';
}
.icon-shortcut:before {
  content: '\e0b5';
}
.icon-lead-outdated:before {
  content: '\e0b6';
}
.icon-tools-opportunity:before {
  content: '\e0b7';
}
.icon-permission:before {
  content: '\e0b8';
}
.icon-supplier:before {
  content: '\e0b9';
}
.icon-table-view:before {
  content: '\e0ba';
}
.icon-table-chart:before {
  content: '\e0bb';
}
.icon-switch-views:before {
  content: '\e0bc';
}
.icon-e-learning:before {
  content: '\e0bd';
}
.icon-manager:before {
  content: '\e0be';
}
.icon-switch-classes:before {
  content: '\e0bf';
}
.icon-simple-payment:before {
  content: '\e0c0';
}
.icon-signature:before {
  content: '\e0c1';
}
.icon-sales-order-item:before {
  content: '\e0c2';
}
.icon-sales-order:before {
  content: '\e0c3';
}
.icon-request:before {
  content: '\e0c4';
}
.icon-receipt:before {
  content: '\e0c5';
}
.icon-puzzle:before {
  content: '\e0c6';
}
.icon-process:before {
  content: '\e0c7';
}
.icon-private:before {
  content: '\e0c8';
}
.icon-popup-window:before {
  content: '\e0c9';
}
.icon-person-placeholder:before {
  content: '\e0ca';
}
.icon-per-diem:before {
  content: '\e0cb';
}
.icon-paper-plane:before {
  content: '\e0cc';
}
.icon-paid-leave:before {
  content: '\e0cd';
}
.icon-pdf-reader:before {
  content: '\e0ce';
}
.icon-overview-chart:before {
  content: '\e0cf';
}
.icon-overlay:before {
  content: '\e0d0';
}
.icon-org-chart:before {
  content: '\e0d1';
}
.icon-number-sign:before {
  content: '\e0d2';
}
.icon-notification-2:before {
  content: '\e0d3';
}
.icon-my-sales-order:before {
  content: '\e0d4';
}
.icon-meal:before {
  content: '\e0d5';
}
.icon-loan:before {
  content: '\e0d6';
}
.icon-order-status:before {
  content: '\e0d7';
}
.icon-customer-order-entry:before {
  content: '\e0d8';
}
.icon-performance:before {
  content: '\e0d9';
}
.icon-menu:before {
  content: '\e0da';
}
.icon-employee-lookup:before {
  content: '\e0db';
}
.icon-education:before {
  content: '\e0dc';
}
.icon-customer-briefing:before {
  content: '\e0dd';
}
.icon-customer-and-contacts:before {
  content: '\e0de';
}
.icon-my-view:before {
  content: '\e0df';
}
.icon-accelerated:before {
  content: '\e0e0';
}
.icon-to-be-reviewed:before {
  content: '\e0e1';
}
.icon-warning2:before {
  content: '\e0e2';
}
.icon-feeder-arrow:before {
  content: '\e0e3';
}
.icon-quality-issue:before {
  content: '\e0e4';
}
.icon-workflow-tasks:before {
  content: '\e0e5';
}
.icon-create:before {
  content: '\e0e6';
}
.icon-home-share:before {
  content: '\e0e7';
}
.icon-globe:before {
  content: '\e0e8';
}
.icon-tags:before {
  content: '\e0e9';
}
.icon-work-history:before {
  content: '\e0ea';
}
.icon-x-ray:before {
  content: '\e0eb';
}
.icon-wounds-doc:before {
  content: '\e0ec';
}
.icon-web-cam:before {
  content: '\e0ed';
}
.icon-waiver:before {
  content: '\e0ee';
}
.icon-vertical-bar-chart:before {
  content: '\e0ef';
}
.icon-upstacked-chart:before {
  content: '\e0f0';
}
.icon-trip-report:before {
  content: '\e0f1';
}
.icon-microphone:before {
  content: '\e0f2';
}
.icon-unpaid-leave:before {
  content: '\e0f3';
}
.icon-tree:before {
  content: '\e0f4';
}
.icon-toaster-up:before {
  content: '\e0f5';
}
.icon-toaster-top:before {
  content: '\e0f6';
}
.icon-toaster-down:before {
  content: '\e0f7';
}
.icon-time-account:before {
  content: '\e0f8';
}
.icon-theater:before {
  content: '\e0f9';
}
.icon-taxi:before {
  content: '\e0fa';
}
.icon-subway-train:before {
  content: '\e0fb';
}
.icon-study-leave:before {
  content: '\e0fc';
}
.icon-stethoscope:before {
  content: '\e0fd';
}
.icon-step:before {
  content: '\e0fe';
}
.icon-sonography:before {
  content: '\e0ff';
}
.icon-soccor:before {
  content: '\e100';
}
.icon-physical-activity:before {
  content: '\e101';
}
.icon-pharmacy:before {
  content: '\e102';
}
.icon-official-service:before {
  content: '\e103';
}
.icon-offsite-work:before {
  content: '\e104';
}
.icon-nutrition-activity:before {
  content: '\e105';
}
.icon-newspaper:before {
  content: '\e106';
}
.icon-monitor-payments:before {
  content: '\e107';
}
.icon-map-2:before {
  content: '\e108';
}
.icon-machine:before {
  content: '\e109';
}
.icon-mri-scan:before {
  content: '\e10a';
}
.icon-end-user-experience-monitoring:before {
  content: '\e10b';
}
.icon-unwired:before {
  content: '\e10c';
}
.icon-customer-financial-fact-sheet:before {
  content: '\e10d';
}
.icon-retail-store-manager:before {
  content: '\e10e';
}
.icon-Netweaver-business-client:before {
  content: '\e10f';
}
.icon-electronic-medical-record:before {
  content: '\e110';
}
.icon-eam-work-order:before {
  content: '\e111';
}
.icon-customer-view:before {
  content: '\e112';
}
.icon-crm-service-manager:before {
  content: '\e113';
}
.icon-crm-sales:before {
  content: '\e114';
}
.icon-widgets:before {
  content: '\e115';
}
.icon-commission-check:before {
  content: '\e116';
}
.icon-collections-insight:before {
  content: '\e117';
}
.icon-clinical-tast-tracker:before {
  content: '\e118';
}
.icon-citizen-connect:before {
  content: '\e119';
}
.icon-cart-approval:before {
  content: '\e11a';
}
.icon-capital-projects:before {
  content: '\e11b';
}
.icon-bo-strategy-management:before {
  content: '\e11c';
}
.icon-business-objects-mobile:before {
  content: '\e11d';
}
.icon-business-objects-explorer:before {
  content: '\e11e';
}
.icon-business-objects-experience:before {
  content: '\e11f';
}
.icon-bbyd-dashboard:before {
  content: '\e120';
}
.icon-bbyd-active-sales:before {
  content: '\e121';
}
.icon-business-by-design:before {
  content: '\e122';
}
.icon-business-one:before {
  content: '\e123';
}
.icon-sap-box:before {
  content: '\e124';
}
.icon-manager-insight:before {
  content: '\e125';
}
.icon-accounting-document-verification:before {
  content: '\e126';
}
.icon-hr-approval:before {
  content: '\e127';
}
.icon-idea-wall:before {
  content: '\e128';
}
.icon-Chart-Tree-Map:before {
  content: '\e129';
}
.icon-cart-5:before {
  content: '\e12a';
}
.icon-cart-4:before {
  content: '\e12b';
}
.icon-wallet:before {
  content: '\e12c';
}
.icon-vehicle-repair:before {
  content: '\e12d';
}
.icon-upload:before {
  content: '\e12e';
}
.icon-unlocked:before {
  content: '\e12f';
}
.icon-umbrella:before {
  content: '\e130';
}
.icon-travel-request:before {
  content: '\e131';
}
.icon-travel-expense-report:before {
  content: '\e132';
}
.icon-travel-itinerary:before {
  content: '\e133';
}
.icon-time-overtime:before {
  content: '\e134';
}
.icon-thing-type:before {
  content: '\e135';
}
.icon-technical-object:before {
  content: '\e136';
}
.icon-tag:before {
  content: '\e137';
}
.icon-syringe:before {
  content: '\e138';
}
.icon-syntax:before {
  content: '\e139';
}
.icon-suitcase:before {
  content: '\e13a';
}
.icon-simulate:before {
  content: '\e13b';
}
.icon-shield:before {
  content: '\e13c';
}
.icon-share-2:before {
  content: '\e13d';
}
.icon-sales-quote:before {
  content: '\e13e';
}
.icon-repost:before {
  content: '\e13f';
}
.icon-provision:before {
  content: '\e140';
}
.icon-projector:before {
  content: '\e141';
}
.icon-add-product:before {
  content: '\e142';
}
.icon-pipeline-analysis:before {
  content: '\e143';
}
.icon-add-photo:before {
  content: '\e144';
}
.icon-palette:before {
  content: '\e145';
}
.icon-nurse:before {
  content: '\e146';
}
.icon-sales-notification:before {
  content: '\e147';
}
.icon-mileage:before {
  content: '\e148';
}
.icon-meeting-room:before {
  content: '\e149';
}
.icon-media-forward:before {
  content: '\e14a';
}
.icon-media-play:before {
  content: '\e14b';
}
.icon-media-pause:before {
  content: '\e14c';
}
.icon-media-reverse:before {
  content: '\e14d';
}
.icon-media-rewind:before {
  content: '\e14e';
}
.icon-measurement-document:before {
  content: '\e14f';
}
.icon-measuring-point:before {
  content: '\e150';
}
.icon-measure:before {
  content: '\e151';
}
.icon-map-3:before {
  content: '\e152';
}
.icon-locked:before {
  content: '\e153';
}
.icon-letter:before {
  content: '\e154';
}
.icon-journey-arrive:before {
  content: '\e155';
}
.icon-journey-change:before {
  content: '\e156';
}
.icon-journey-depart:before {
  content: '\e157';
}
.icon-it-system:before {
  content: '\e158';
}
.icon-it-instance:before {
  content: '\e159';
}
.icon-it-host:before {
  content: '\e15a';
}
.icon-iphone-2:before {
  content: '\e15b';
}
.icon-ipad-2:before {
  content: '\e15c';
}
.icon-inventory:before {
  content: '\e15d';
}
.icon-insurance-house:before {
  content: '\e15e';
}
.icon-insurance-life:before {
  content: '\e15f';
}
.icon-insurance-car:before {
  content: '\e160';
}
.icon-initiative:before {
  content: '\e161';
}
.icon-incident:before {
  content: '\e162';
}
.icon-group-2:before {
  content: '\e163';
}
.icon-goal:before {
  content: '\e164';
}
.icon-functional-location:before {
  content: '\e165';
}
.icon-full-screen:before {
  content: '\e166';
}
.icon-form:before {
  content: '\e167';
}
.icon-fob-watch:before {
  content: '\e168';
}
.icon-blank-tag:before {
  content: '\e169';
}
.icon-family-protection:before {
  content: '\e16a';
}
.icon-folder:before {
  content: '\e16b';
}
.icon-fax-machine:before {
  content: '\e16c';
}
.icon-example:before {
  content: '\e16d';
}
.icon-eraser:before {
  content: '\e16e';
}
.icon-employee-rejections:before {
  content: '\e16f';
}
.icon-drop-down-list:before {
  content: '\e170';
}
.icon-draw-rectangle:before {
  content: '\e171';
}
.icon-document:before {
  content: '\e172';
}
.icon-doctor:before {
  content: '\e173';
}
.icon-discussion-2:before {
  content: '\e174';
}
.icon-discussion:before {
  content: '\e175';
}
.icon-dimension:before {
  content: '\e176';
}
.icon-customer-and-supplier:before {
  content: '\e177';
}
.icon-crop:before {
  content: '\e178';
}
.icon-add-contact:before {
  content: '\e179';
}
.icon-compare-2:before {
  content: '\e17a';
}
.icon-color-fill:before {
  content: '\e17b';
}
.icon-collision:before {
  content: '\e17c';
}
.icon-curriculum:before {
  content: '\e17d';
}
.icon-chart-axis:before {
  content: '\e17e';
}
.icon-full-stacked-chart:before {
  content: '\e17f';
}
.icon-full-stacked-column-chart:before {
  content: '\e180';
}
.icon-vertical-bar-chart-2:before {
  content: '\e181';
}
.icon-horizontal-bar-chart-2:before {
  content: '\e182';
}
.icon-horizontal-stacked-chart:before {
  content: '\e183';
}
.icon-vertical-stacked-chart:before {
  content: '\e184';
}
.icon-choropleth-chart:before {
  content: '\e185';
}
.icon-geographic-bubble-chart:before {
  content: '\e186';
}
.icon-multiple-radar-chart:before {
  content: '\e187';
}
.icon-radar-chart:before {
  content: '\e188';
}
.icon-crossed-line-chart:before {
  content: '\e189';
}
.icon-multiple-line-chart:before {
  content: '\e18a';
}
.icon-multiple-bar-chart:before {
  content: '\e18b';
}
.icon-line-chart:before {
  content: '\e18c';
}
.icon-line-chart-dual-axis:before {
  content: '\e18d';
}
.icon-bubble-chart:before {
  content: '\e18e';
}
.icon-scatter-chart:before {
  content: '\e18f';
}
.icon-multiple-pie-chart:before {
  content: '\e190';
}
.icon-column-chart-dual-axis:before {
  content: '\e191';
}
.icon-tag-cloud-chart:before {
  content: '\e192';
}
.icon-area-chart:before {
  content: '\e193';
}
.icon-cause:before {
  content: '\e194';
}
.icon-cart-3:before {
  content: '\e195';
}
.icon-cart-2:before {
  content: '\e196';
}
.icon-bus-public-transport:before {
  content: '\e197';
}
.icon-burglary:before {
  content: '\e198';
}
.icon-building:before {
  content: '\e199';
}
.icon-border:before {
  content: '\e19a';
}
.icon-bookmark:before {
  content: '\e19b';
}
.icon-badge:before {
  content: '\e19c';
}
.icon-attachment-audio:before {
  content: '\e19d';
}
.icon-attachment-video:before {
  content: '\e19e';
}
.icon-attachment-html:before {
  content: '\e19f';
}
.icon-attachment-photo:before {
  content: '\e1a0';
}
.icon-attachment-e-pub:before {
  content: '\e1a1';
}
.icon-attachment-zip-file:before {
  content: '\e1a2';
}
.icon-attachment-text-file:before {
  content: '\e1a3';
}
.icon-add-equipment:before {
  content: '\e1a4';
}
.icon-add-activity:before {
  content: '\e1a5';
}
.icon-activity-individual:before {
  content: '\e1a6';
}
.icon-activity-2:before {
  content: '\e1a7';
}
.icon-add-activity-2:before {
  content: '\e1a8';
}
.icon-activity-items:before {
  content: '\e1a9';
}
.icon-activity-assigned-to-goal:before {
  content: '\e1aa';
}

/* duplicate icon with alternative class name for compatibility reasons */
.icon-status-positive:before {
  content: '\e1ab';
}
.icon-status-completed:before {
  content: '\e1ab';
}

/* duplicate icon with alternative class name for compatibility reasons */
.icon-status-negative:before {
  content: '\e1ac';
}
.icon-status-error:before {
  content: '\e1ac';
}

.icon-status-inactive:before {
  content: '\e1ad';
}

/* duplicate icon with alternative class name for compatibility reasons */
.icon-status-critical:before {
  content: '\e1ae';
}
.icon-status-in-progress:before {
  content: '\e1ae';
}

.icon-blank-tag-2:before {
  content: '\e1af';
}
.icon-cart-full:before {
  content: '\e1b0';
}
.icon-locate-me:before {
  content: '\e1b1';
}
.icon-paging:before {
  content: '\e1b2';
}
.icon-company-view:before {
  content: '\e1b3';
}
.icon-document-text:before {
  content: '\e1b4';
}
.icon-explorer:before {
  content: '\e1b5';
}
.icon-personnel-view:before {
  content: '\e1b6';
}
.icon-sorting-ranking:before {
  content: '\e1b7';
}
.icon-drill-down:before {
  content: '\e1b8';
}
.icon-drill-up:before {
  content: '\e1b9';
}
.icon-vds-file:before {
  content: '\e1ba';
}
.icon-sap-logo-shape:before {
  content: '\e1bb';
}

.icon-folder-full:before {
  content: '\e1bc';
}

.icon-system-exit:before {
  content: '\e1bd';
}

.icon-system-exit-2:before {
  content: '\e1be';
}

.icon-close-command-field:before {
  content: '\e1bf';
}

.icon-open-command-field:before {
  content: '\e1c0';
}

.icon-sys-enter-2:before {
  content: '\e1c1';
}

.icon-sys-enter:before {
  content: '\e1c2';
}

.icon-sys-help-2:before {
  content: '\e1c3';
}

.icon-sys-help:before {
  content: '\e1c4';
}

.icon-sys-back:before {
  content: '\e1c5';
}

.icon-sys-back-2:before {
  content: '\e1c6';
}

.icon-sys-cancel:before {
  content: '\e1c7';
}

.icon-sys-cancel-2:before {
  content: '\e1c8';
}

.icon-open-folder:before {
  content: '\e1c9';
}

.icon-sys-find-next:before {
  content: '\e1ca';
}

.icon-sys-find:before {
  content: '\e1cb';
}

.icon-sys-monitor:before {
  content: '\e1cc';
}

.icon-sys-prev-page:before {
  content: '\e1cd';
}

.icon-sys-first-page:before {
  content: '\e1ce';
}

.icon-sys-next-page:before {
  content: '\e1cf';
}

.icon-sys-last-page:before {
  content: '\e1d0';
}

.icon-generate-shortcut:before {
  content: '\e1d1';
}

.icon-create-session:before {
  content: '\e1d2';
}

.icon-display-more:before {
  content: '\e1d3';
}

.icon-enter-more:before {
  content: '\e1d4';
}

.icon-zoom-in:before {
  content: '\e1d5';
}

.icon-zoom-out:before {
  content: '\e1d6';
}

.icon-header:before {
  content: '\e1d7';
}

/* duplicate icon with alternative class name for compatibility reasons */
.icon-detail-view:before {
  content: '\e1d8';
}
.icon-show-edit:before {
  content: '\e1d8';
}

.icon-collapse:before {
  content: '\e1d9';
}

.icon-expand:before {
  content: '\e1da';
}

.icon-positive:before {
  content: '\e1db';
}

.icon-negative:before {
  content: '\e1dc';
}

.icon-display:before {
  content: '\e1dd';
}

.icon-menu2:before {
  content: '\e1de';
}

.icon-redo:before {
  content: '\e1df';
}

.icon-undo:before {
  content: '\e1e0';
}

.icon-navigation-up-arrow:before {
  content: '\e1e1';
}

.icon-navigation-down-arrow:before {
  content: '\e1e2';
}

.icon-down:before {
  content: '\e1e3';
}

.icon-up:before {
  content: '\e1e4';
}

.icon-shelf:before {
  content: '\e1e5';
}

.icon-background:before {
  content: '\e1e6';
}

.icon-resize:before {
  content: '\e1e7';
}

.icon-move:before {
  content: '\e1e8';
}

.icon-show:before {
  content: '\e1e9';
}

.icon-hide:before {
  content: '\e1ea';
}

.icon-nav-back:before {
  content: '\e1eb';
}

.icon-error:before {
  content: '\e1ec';
}

.icon-slim-arrow-right:before {
  content: '\e1ed';
}

.icon-slim-arrow-left:before {
  content: '\e1ee';
}

.icon-slim-arrow-down:before {
  content: '\e1ef';
}

.icon-slim-arrow-up:before {
  content: '\e1f0';
}

.icon-forward:before {
  content: '\e1f1';
}

.icon-overflow:before {
  content: '\e1f2';
}

.icon-value-help:before {
  content: '\e1f3';
}

.icon-multiselect:before {
  content: '\e1f4';
}

.icon-exitfullscreen:before {
  content: '\e1f5';
}

.icon-sys-add:before {
  content: '\e1f6';
}

.icon-sys-minus:before {
  content: '\e1f7';
}

.icon-dropdown:before {
  content: '\e1f8';
}

.icon-expand-group:before {
  content: '\e1f9';
}

.icon-vertical-grip:before {
  content: '\e1fa';
}

.icon-horizontal-grip:before {
  content: '\e1fb';
}

.icon-sort-descending:before {
  content: '\e1fc';
}

.icon-sort-ascending:before {
  content: '\e1fd';
}

.icon-arrow-down:before {
  content: '\e1fe';
}

.icon-legend:before {
  content: '\e1ff';
}

.icon-collapse-group:before {
  content: '\e200';
}

.icon-message-warning:before {
  content: '\e201';
}

.icon-message-information:before {
  content: '\e202';
}

.icon-message-success:before {
  content: '\e203';
}

.icon-restart:before {
  content: '\e204';
}

.icon-stop:before {
  content: '\e205';
}

.icon-add-process:before {
  content: '\e206';
}

.icon-cancel-maintenance:before {
  content: '\e207';
}

.icon-activate:before {
  content: '\e208';
}

.icon-resize-horizontal:before {
  content: '\e209';
}

.icon-resize-vertical:before {
  content: '\e20a';
}

.icon-connected:before {
  content: '\e20b';
}

.icon-disconnected:before {
  content: '\e20c';
}

.icon-edit-outside:before {
  content: '\e20d';
}

.icon-key:before {
  content: '\e20e';
}

.icon-minimize:before {
  content: '\e20f';
}

.icon-back-to-top:before {
  content: '\e210';
}

.icon-hello-world:before {
  content: '\e211';
}

.icon-outbox:before {
  content: '\e212';
}

.icon-donut-chart:before {
  content: '\e213';
}

.icon-heatmap-chart:before {
  content: '\e214';
}

.icon-horizontal-bullet-chart:before {
  content: '\e215';
}

.icon-vertical-bullet-chart:before {
  content: '\e216';
}

.icon-call:before {
  content: '\e217';
}

.icon-download-from-cloud:before {
  content: '\e218';
}

.icon-upload-to-cloud:before {
  content: '\e219';
}

.icon-jam:before {
  content: '\e21a';
}

.icon-sap-ui5:before {
  content: '\e21b';
}

.icon-message-popup:before {
  content: '\e21c';
}

.icon-cloud:before {
  content: '\e21d';
}

.icon-horizontal-waterfall-chart:before {
  content: '\e21e';
}

.icon-vertical-waterfall-chart:before {
  content: '\e21f';
}

.icon-broken-link:before {
  content: '\e220';
}

.icon-headset:before {
  content: '\e221';
}

.icon-thumb-up:before {
  content: '\e222';
}

.icon-thumb-down:before {
  content: '\e223';
}

.icon-multiselect-all:before {
  content: '\e224';
}

.icon-multiselect-none:before {
  content: '\e225';
}

.icon-scissors:before {
  content: '\e226';
}

.icon-sound:before {
  content: '\e227';
}

.icon-sound-loud:before {
  content: '\e228';
}

.icon-sound-off:before {
  content: '\e229';
}

.icon-date-time:before {
  content: '\e22a';
}

.icon-user-settings:before {
  content: '\e22b';
}

.icon-key-user-settings:before {
  content: '\e23c';
}

.icon-developer-settings:before {
  content: '\e22d';
}

.icon-text-formatting:before {
  content: '\e22e';
}

.icon-bold-text:before {
  content: '\e22f';
}

.icon-italic-text:before {
  content: '\e230';
}

.icon-underline-text:before {
  content: '\e231';
}

.icon-text-align-justified:before {
  content: '\e232';
}

.icon-text-align-left:before {
  content: '\e233';
}

.icon-text-align-center:before {
  content: '\e234';
}

.icon-text-align-right:before {
  content: '\e235';
}

.icon-bullet-text:before {
  content: '\e236';
}

.icon-numbered-text:before {
  content: '\e237';
}

.icon-co:before {
  content: '\e238';
}

.icon-ui-notifications:before {
  content: '\e239';
}

.icon-bell:before {
  content: '\e23a';
}

.icon-cancel-share:before {
  content: '\e23b';
}

.icon-write-new-document:before {
  content: '\e23c';
}

.icon-write-new:before {
  content: '\e23d';
}

.icon-cancel:before {
  content: '\e23e';
}

.icon-screen-split-one:before {
  content: '\e23f';
}

.icon-screen-split-two:before {
  content: '\e240';
}

.icon-screen-split-three:before {
  content: '\e241';
}

.icon-customize:before {
  content: '\e242';
}

.icon-user-edit:before {
  content: '\e243';
}

.icon-source-code:before {
  content: '\e244';
}

.icon-copy:before {
  content: '\e245';
}

.icon-paste:before {
  content: '\e246';
}

.icon-line-chart-time-axis:before {
  content: '\e247';
}

.icon-clear-filter:before {
  content: '\e248';
}

.icon-reset:before {
  content: '\e249';
}

.icon-trend-up:before {
  content: '\e24a';
}

.icon-trend-down:before {
  content: '\e24b';
}

.icon-cursor:before {
  content: '\e24c';
}

.icon-add-document:before {
  content: '\e24d';
}

.icon-create-form:before {
  content: '\e24e';
}

.icon-resize-corner:before {
  content: '\e24f';
}

.icon-chevron-phase:before {
  content: '\e250';
}

.icon-chevron-phase-2:before {
  content: '\e251';
}

.icon-rhombus-milestone:before {
  content: '\e252';
}

.icon-rhombus-milestone-2:before {
  content: '\e253';
}

.icon-circle-task:before {
  content: '\e254';
}

.icon-circle-task-2:before {
  content: '\e255';
}

.icon-project-definition-triangle:before {
  content: '\e256';
}

.icon-project-definition-triangle-2:before {
  content: '\e257';
}

.icon-master-task-triangle:before {
  content: '\e258';
}

.icon-master-task-triangle-2:before {
  content: '\e259';
}

.icon-program-triangles:before {
  content: '\e25a';
}

.icon-program-triangles-2:before {
  content: '\e25b';
}

.icon-mirrored-task-circle:before {
  content: '\e25c';
}

.icon-mirrored-task-circle-2:before {
  content: '\e25d';
}

.icon-checklist-item:before {
  content: '\e25e';
}

.icon-checklist-item-2:before {
  content: '\e25f';
}

.icon-checklist:before {
  content: '\e260';
}

.icon-checklist-2:before {
  content: '\e261';
}

.icon-chart-table-view:before {
  content: '\e262';
}

.icon-filter-analytics:before {
  content: '\e263';
}

.icon-filter-facets:before {
  content: '\e264';
}

.icon-filter-fields:before {
  content: '\e265';
}

.icon-indent:before {
  content: '\e266';
}

.icon-outdent:before {
  content: '\e267';
}

.icon-heading-1:before {
  content: '\e268';
}

.icon-heading-2:before {
  content: '\e269';
}

.icon-heading-3:before {
  content: '\e26a';
}

.icon-decrease-line-height:before {
  content: '\e26b';
}

.icon-increase-line-height:before {
  content: '\e26c';
}

.icon-fx:before {
  content: '\e26d';
}

.icon-add-folder:before {
  content: '\e26e';
}

.icon-away:before {
  content: '\e26f';
}

.icon-busy:before {
  content: '\e270';
}

.icon-appear-offline:before {
  content: '\e271';
}

.icon-blur:before {
  content: '\e272';
}

.icon-pixelate:before {
  content: '\e273';
}

.icon-horizontal-combination-chart:before {
  content: '\e274';
}

.icon-add-employee:before {
  content: '\e275';
}

.icon-text-color:before {
  content: '\e276';
}

.icon-browse-folder:before {
  content: '\e277';
}

.icon-primary-key:before {
  content: '\e278';
}

.icon-two-keys:before {
  content: '\e279';
}

.icon-strikethrough:before {
  content: '\e27a';
}

.icon-text:before {
  content: '\e27b';
}

.icon-responsive:before {
  content: '\e27c';
}

.icon-desktop-mobile:before {
  content: '\e27d';
}

.icon-table-row:before {
  content: '\e27e';
}

.icon-table-column:before {
  content: '\e27f';
}

.icon-validate:before {
  content: '\e280';
}

.icon-keyboard-and-mouse:before {
  content: '\e281b';
}

.icon-touch:before {
  content: '\e282';
}

.icon-expand-all:before {
  content: '\e283';
}

.icon-collapse-all:before {
  content: '\e284';
}

.icon-combine:before {
  content: '\e285';
}

.icon-collapse-all:before {
  content: '\e286';
}

.icon-accelerated:before {
  content: '\e0e0';
}

.icon-accept:before {
  content: '\e05b';
}

.icon-accidental-leave:before {
  content: '\e000';
}

.icon-account:before {
  content: '\e001';
}

.icon-accounting-document-verification:before {
  content: '\e126';
}

.icon-action-settings:before {
  content: '\e0a6';
}

.icon-action:before {
  content: '\e05a';
}

.icon-activate:before {
  content: '\e208';
}

.icon-activities:before {
  content: '\e059';
}

.icon-activity-2:before {
  content: '\e1a7';
}

.icon-activity-assigned-to-goal:before {
  content: '\e1aa';
}

.icon-activity-individual:before {
  content: '\e1a6';
}

.icon-activity-items:before {
  content: '\e1a9';
}

.icon-add-activity-2:before {
  content: '\e1a8';
}

.icon-add-activity:before {
  content: '\e1a5';
}

.icon-add-contact:before {
  content: '\e179';
}

.icon-add-coursebook:before {
  content: '\e098';
}

.icon-add-document:before {
  content: '\e24d';
}

.icon-add-employee:before {
  content: '\e275';
}

.icon-add-equipment:before {
  content: '\e1a4';
}

.icon-add-favorite:before {
  content: '\e057';
}

.icon-add-filter:before {
  content: '\e056';
}

.icon-add-folder:before {
  content: '\e26e';
}

.icon-add-photo:before {
  content: '\e144';
}

.icon-add-process:before {
  content: '\e206';
}

.icon-add-product:before {
  content: '\e142';
}

.icon-add:before {
  content: '\e058';
}

.icon-address-book:before {
  content: '\e055';
}

.icon-addresses:before {
  content: '\e054';
}

.icon-alert:before {
  content: '\e053';
}

.icon-along-stacked-chart:before {
  content: '\e052';
}

.icon-alphabetical-order:before {
  content: '\e051';
}

.icon-appear-offline:before {
  content: '\e271';
}

.icon-appointment-2:before {
  content: '\e0ae';
}

.icon-appointment:before {
  content: '\e050';
}

.icon-approvals:before {
  content: '\e04f';
}

.icon-area-chart:before {
  content: '\e193';
}

.icon-arobase:before {
  content: '\e063';
}

.icon-arrow-bottom:before {
  content: '\e04e';
}

.icon-arrow-down:before {
  content: '\e1fe';
}

.icon-arrow-left:before {
  content: '\e04d';
}

.icon-arrow-right:before {
  content: '\e04c';
}

.icon-arrow-top:before {
  content: '\e04b';
}

.icon-attachment-audio:before {
  content: '\e19d';
}

.icon-attachment-e-pub:before {
  content: '\e1a1';
}

.icon-attachment-html:before {
  content: '\e19f';
}

.icon-attachment-photo:before {
  content: '\e1a0';
}

.icon-attachment-text-file:before {
  content: '\e1a3';
}

.icon-attachment-video:before {
  content: '\e19e';
}

.icon-attachment-zip-file:before {
  content: '\e1a2';
}

.icon-attachment:before {
  content: '\e04a';
}

.icon-away:before {
  content: '\e26f';
}

.icon-back-to-top:before {
  content: '\e210';
}

.icon-background:before {
  content: '\e1e6';
}

.icon-badge:before {
  content: '\e19c';
}

.icon-bar-chart:before {
  content: '\e049';
}

.icon-bar-code:before {
  content: '\e08d';
}

.icon-basket:before {
  content: '\e048';
}

.icon-batch-payments:before {
  content: '\e061';
}

.icon-bbyd-active-sales:before {
  content: '\e121';
}

.icon-bbyd-dashboard:before {
  content: '\e120';
}

.icon-bed:before {
  content: '\e062';
}

.icon-begin:before {
  content: '\e047';
}

.icon-bell:before {
  content: '\e23a';
}

.icon-blank-tag-2:before {
  content: '\e1af';
}

.icon-blank-tag:before {
  content: '\e169';
}

.icon-blur:before {
  content: '\e272';
}

.icon-bo-strategy-management:before {
  content: '\e11c';
}

.icon-bold-text:before {
  content: '\e22f';
}

.icon-bookmark:before {
  content: '\e19b';
}

.icon-border:before {
  content: '\e19a';
}

.icon-broken-link:before {
  content: '\e220';
}

.icon-browse-folder:before {
  content: '\e277';
}

.icon-bubble-chart:before {
  content: '\e18e';
}

.icon-building:before {
  content: '\e199';
}

.icon-bullet-text:before {
  content: '\e236';
}

.icon-burglary:before {
  content: '\e198';
}

.icon-bus-public-transport:before {
  content: '\e197';
}

.icon-business-by-design:before {
  content: '\e122';
}

.icon-business-card:before {
  content: '\e08c';
}

.icon-business-objects-experience:before {
  content: '\e11f';
}

.icon-business-objects-explorer:before {
  content: '\e11e';
}

.icon-business-objects-mobile:before {
  content: '\e11d';
}

.icon-business-one:before {
  content: '\e123';
}

.icon-busy:before {
  content: '\e270';
}

.icon-calendar:before {
  content: '\e046';
}

.icon-call:before {
  content: '\e217';
}

.icon-camera:before {
  content: '\e045';
}

.icon-cancel-maintenance:before {
  content: '\e207';
}

.icon-cancel-share:before {
  content: '\e23b';
}

.icon-cancel:before {
  content: '\e23e';
}

.icon-capital-projects:before {
  content: '\e11b';
}

.icon-car-rental:before {
  content: '\e08b';
}

.icon-card:before {
  content: '\e044';
}

.icon-cargo-train:before {
  content: '\e08a';
}

.icon-cart-2:before {
  content: '\e196';
}

.icon-cart-3:before {
  content: '\e195';
}

.icon-cart-4:before {
  content: '\e12b';
}

.icon-cart-5:before {
  content: '\e12a';
}

.icon-cart-approval:before {
  content: '\e11a';
}

.icon-cart-full:before {
  content: '\e1b0';
}

.icon-cart:before {
  content: '\e043';
}

.icon-cause:before {
  content: '\e194';
}

.icon-chain-link:before {
  content: '\e088';
}

.icon-chalkboard:before {
  content: '\e042';
}

.icon-chart-axis:before {
  content: '\e17e';
}

.icon-chart-table-view:before {
  content: '\e262';
}

.icon-Chart-Tree-Map:before {
  content: '\e129';
}

.icon-check-availability:before {
  content: '\e05e';
}

.icon-checklist-2:before {
  content: '\e261';
}

.icon-checklist-item-2:before {
  content: '\e25f';
}

.icon-checklist-item:before {
  content: '\e25e';
}

.icon-checklist:before {
  content: '\e260';
}

.icon-chevron-phase-2:before {
  content: '\e251';
}

.icon-chevron-phase:before {
  content: '\e250';
}

.icon-choropleth-chart:before {
  content: '\e185';
}

.icon-circle-task-2:before {
  content: '\e255';
}

.icon-circle-task:before {
  content: '\e254';
}

.icon-citizen-connect:before {
  content: '\e119';
}

.icon-clear-filter:before {
  content: '\e248';
}

.icon-clinical-order:before {
  content: '\e087';
}

.icon-clinical-tast-tracker:before {
  content: '\e118';
}

.icon-close-command-field:before {
  content: '\e1bf';
}

.icon-cloud:before {
  content: '\e21d';
}

.icon-co:before {
  content: '\e238';
}

.icon-collaborate:before {
  content: '\e0b4';
}

.icon-collapse-all:before {
  content: '\e284';
}

.icon-collapse-group:before {
  content: '\e200';
}

.icon-collapse:before {
  content: '\e1d9';
}

.icon-collections-insight:before {
  content: '\e117';
}

.icon-collections-management:before {
  content: '\e041';
}

.icon-collision:before {
  content: '\e17c';
}

.icon-color-fill:before {
  content: '\e17b';
}

.icon-column-chart-dual-axis:before {
  content: '\e191';
}

.icon-combine:before {
  content: '\e285';
}

.icon-comment:before {
  content: '\e0b2';
}

.icon-commission-check:before {
  content: '\e116';
}

.icon-company-view:before {
  content: '\e1b3';
}

.icon-compare-2:before {
  content: '\e17a';
}

.icon-compare:before {
  content: '\e086';
}

.icon-competitor:before {
  content: '\e040';
}

.icon-complete:before {
  content: '\e03f';
}

.icon-connected:before {
  content: '\e20b';
}

.icon-contacts:before {
  content: '\e085';
}

.icon-copy:before {
  content: '\e245';
}

.icon-course-book:before {
  content: '\e096';
}

.icon-course-program:before {
  content: '\e097';
}

.icon-create-entry-time:before {
  content: '\e084';
}

.icon-create-form:before {
  content: '\e24e';
}

.icon-create-leave-request:before {
  content: '\e074';
}

.icon-create-session:before {
  content: '\e1d2';
}

.icon-create:before {
  content: '\e0e6';
}

.icon-credit-card:before {
  content: '\e083';
}

.icon-crm-sales:before {
  content: '\e114';
}

.icon-crm-service-manager:before {
  content: '\e113';
}

.icon-crop:before {
  content: '\e178';
}

.icon-crossed-line-chart:before {
  content: '\e189';
}

.icon-curriculum:before {
  content: '\e17d';
}

.icon-cursor:before {
  content: '\e24c';
}

.icon-customer-and-contacts:before {
  content: '\e0de';
}

.icon-customer-and-supplier:before {
  content: '\e177';
}

.icon-customer-briefing:before {
  content: '\e0dd';
}

.icon-customer-financial-fact-sheet:before {
  content: '\e10d';
}

.icon-customer-history:before {
  content: '\e081';
}

.icon-customer-order-entry:before {
  content: '\e0d8';
}

.icon-customer-view:before {
  content: '\e112';
}

.icon-customer:before {
  content: '\e082';
}

.icon-customize:before {
  content: '\e242';
}

.icon-database:before {
  content: '\e080';
}

.icon-date-time:before {
  content: '\e22a';
}

.icon-decision:before {
  content: '\e07f';
}

.icon-decline:before {
  content: '\e03e';
}

.icon-decrease-line-height:before {
  content: '\e26b';
}

.icon-delete:before {
  content: '\e03d';
}

.icon-desktop-mobile:before {
  content: '\e27d';
}

.icon-detail-view:before {
  content: '\e1d8';
}

.icon-developer-settings:before {
  content: '\e22d';
}

.icon-dimension:before {
  content: '\e176';
}

.icon-disconnected:before {
  content: '\e20c';
}

.icon-discussion-2:before {
  content: '\e174';
}

.icon-discussion:before {
  content: '\e175';
}

.icon-dishwasher:before {
  content: '\e03c';
}

.icon-display-more:before {
  content: '\e1d3';
}

.icon-display:before {
  content: '\e1dd';
}

.icon-doc-attachment:before {
  content: '\e03b';
}

.icon-doctor:before {
  content: '\e173';
}

.icon-document-text:before {
  content: '\e1b4';
}

.icon-document:before {
  content: '\e172';
}

.icon-documents:before {
  content: '\e07e';
}

.icon-donut-chart:before {
  content: '\e213';
}

.icon-down:before {
  content: '\e1e3';
}

.icon-download-from-cloud:before {
  content: '\e218';
}

.icon-download:before {
  content: '\e03a';
}

.icon-draw-rectangle:before {
  content: '\e171';
}

.icon-drill-down:before {
  content: '\e1b8';
}

.icon-drill-up:before {
  content: '\e1b9';
}

.icon-drop-down-list:before {
  content: '\e170';
}

.icon-dropdown:before {
  content: '\e1f8';
}

.icon-duplicate:before {
  content: '\e039';
}

.icon-e-care:before {
  content: '\e068';
}

.icon-e-learning:before {
  content: '\e0bd';
}

.icon-eam-work-order:before {
  content: '\e111';
}

.icon-edit-outside:before {
  content: '\e20d';
}

.icon-edit:before {
  content: '\e038';
}

.icon-education:before {
  content: '\e0dc';
}

.icon-electrocardiogram:before {
  content: '\e07d';
}

.icon-electronic-medical-record:before {
  content: '\e110';
}

.icon-email-read:before {
  content: '\e07c';
}

.icon-email:before {
  content: '\e037';
}

.icon-employee-approvals:before {
  content: '\e07b';
}

.icon-employee-lookup:before {
  content: '\e0db';
}

.icon-employee-pane:before {
  content: '\e07a';
}

.icon-employee-rejections:before {
  content: '\e16f';
}

.icon-employee:before {
  content: '\e036';
}

.icon-end-user-experience-monitoring:before {
  content: '\e10b';
}

.icon-endoscopy:before {
  content: '\e079';
}

.icon-energy-saving-lightbulb:before {
  content: '\e035';
}

.icon-enter-more:before {
  content: '\e1d4';
}

.icon-eraser:before {
  content: '\e16e';
}

.icon-error:before {
  content: '\e1ec';
}

.icon-example:before {
  content: '\e16d';
}

.icon-excel-attachment:before {
  content: '\e034';
}

.icon-exitfullscreen:before {
  content: '\e1f5';
}

.icon-expand-all:before {
  content: '\e283';
}

.icon-expand-group:before {
  content: '\e1f9';
}

.icon-expand:before {
  content: '\e1da';
}

.icon-expense-report:before {
  content: '\e033';
}

.icon-explorer:before {
  content: '\e1b5';
}

.icon-factory:before {
  content: '\e078';
}

.icon-fallback:before {
  content: '\e032';
}

.icon-family-care:before {
  content: '\e064';
}

.icon-family-protection:before {
  content: '\e16a';
}

.icon-favorite-list:before {
  content: '\e077';
}

.icon-favorite:before {
  content: '\e065';
}

.icon-fax-machine:before {
  content: '\e16c';
}

.icon-feed:before {
  content: '\e0a8';
}

.icon-feeder-arrow:before {
  content: '\e0e3';
}

.icon-filter-analytics:before {
  content: '\e263';
}

.icon-filter-facets:before {
  content: '\e264';
}

.icon-filter-fields:before {
  content: '\e265';
}

.icon-filter:before {
  content: '\e076';
}

.icon-flag:before {
  content: '\e0aa';
}

.icon-flight:before {
  content: '\e075';
}

.icon-fob-watch:before {
  content: '\e168';
}

.icon-folder-blank:before {
  content: '\e08e';
}

.icon-folder-full:before {
  content: '\e1bc';
}

.icon-folder:before {
  content: '\e16b';
}

.icon-form:before {
  content: '\e167';
}

.icon-forward:before {
  content: '\e1f1';
}

.icon-fridge:before {
  content: '\e031';
}

.icon-full-screen:before {
  content: '\e166';
}

.icon-full-stacked-chart:before {
  content: '\e17f';
}

.icon-full-stacked-column-chart:before {
  content: '\e180';
}

.icon-functional-location:before {
  content: '\e165';
}

.icon-future:before {
  content: '\e030';
}

.icon-fx:before {
  content: '\e26d';
}

.icon-gantt-bars:before {
  content: '\e02f';
}

.icon-general-leave-request:before {
  content: '\e073';
}

.icon-generate-shortcut:before {
  content: '\e1d1';
}

.icon-geographic-bubble-chart:before {
  content: '\e186';
}

.icon-globe:before {
  content: '\e0e8';
}

.icon-goal:before {
  content: '\e164';
}

.icon-goalseek:before {
  content: '\e072';
}

.icon-grid:before {
  content: '\e071';
}

.icon-group-2:before {
  content: '\e163';
}

.icon-group:before {
  content: '\e05d';
}

.icon-header:before {
  content: '\e1d7';
}

.icon-heading-1:before {
  content: '\e268';
}

.icon-heading-2:before {
  content: '\e269';
}

.icon-heading-3:before {
  content: '\e26a';
}

.icon-headset:before {
  content: '\e221';
}

.icon-heating-cooling:before {
  content: '\e02e';
}

.icon-heatmap-chart:before {
  content: '\e214';
}

.icon-hello-world:before {
  content: '\e211';
}

.icon-hide:before {
  content: '\e1ea';
}

.icon-hint:before {
  content: '\e05c';
}

.icon-history:before {
  content: '\e02d';
}

.icon-home-share:before {
  content: '\e0e7';
}

.icon-home:before {
  content: '\e070';
}

.icon-horizontal-bar-chart-2:before {
  content: '\e182';
}

.icon-horizontal-bar-chart:before {
  content: '\e02c';
}

.icon-horizontal-bullet-chart:before {
  content: '\e215';
}

.icon-horizontal-combination-chart:before {
  content: '\e274';
}

.icon-horizontal-grip:before {
  content: '\e1fb';
}

.icon-horizontal-stacked-chart:before {
  content: '\e183';
}

.icon-horizontal-waterfall-chart:before {
  content: '\e21e';
}

.icon-hr-approval:before {
  content: '\e127';
}

.icon-idea-wall:before {
  content: '\e128';
}

.icon-image-viewer:before {
  content: '\e06f';
}

.icon-inbox:before {
  content: '\e02b';
}

.icon-incident:before {
  content: '\e162';
}

.icon-incoming-call:before {
  content: '\e02a';
}

.icon-increase-line-height:before {
  content: '\e26c';
}

.icon-indent:before {
  content: '\e266';
}

.icon-initiative:before {
  content: '\e161';
}

.icon-inspect-down:before {
  content: '\e0ad';
}

.icon-inspect:before {
  content: '\e0ac';
}

.icon-inspection:before {
  content: '\e06e';
}

.icon-instance:before {
  content: '\e06d';
}

.icon-insurance-car:before {
  content: '\e160';
}

.icon-insurance-house:before {
  content: '\e15e';
}

.icon-insurance-life:before {
  content: '\e15f';
}

.icon-internet-browser:before {
  content: '\e06c';
}

.icon-inventory:before {
  content: '\e15d';
}

.icon-ipad-2:before {
  content: '\e15c';
}

.icon-ipad:before {
  content: '\e093';
}

.icon-iphone-2:before {
  content: '\e15b';
}

.icon-iphone:before {
  content: '\e092';
}

.icon-it-host:before {
  content: '\e15a';
}

.icon-it-instance:before {
  content: '\e159';
}

.icon-it-system:before {
  content: '\e158';
}

.icon-italic-text:before {
  content: '\e230';
}

.icon-jam:before {
  content: '\e21a';
}

.icon-journey-arrive:before {
  content: '\e155';
}

.icon-journey-change:before {
  content: '\e156';
}

.icon-journey-depart:before {
  content: '\e157';
}

.icon-key-user-settings:before {
  content: '\e23c';
}

.icon-key:before {
  content: '\e20e';
}

.icon-keyboard-and-mouse:before {
  content: '\e281b';
}

.icon-kpi-corporate-performance:before {
  content: '\e029';
}

.icon-kpi-managing-my-area:before {
  content: '\e028';
}

.icon-lab:before {
  content: '\e06b';
}

.icon-laptop:before {
  content: '\e027';
}

.icon-lateness:before {
  content: '\e06a';
}

.icon-lead-outdated:before {
  content: '\e0b6';
}

.icon-lead:before {
  content: '\e026';
}

.icon-leads:before {
  content: '\e025';
}

.icon-learning-assistant:before {
  content: '\e0a2';
}

.icon-legend:before {
  content: '\e1ff';
}

.icon-less:before {
  content: '\e069';
}

.icon-letter:before {
  content: '\e154';
}

.icon-lightbulb:before {
  content: '\e024';
}

.icon-line-chart-dual-axis:before {
  content: '\e18d';
}

.icon-line-chart-time-axis:before {
  content: '\e247';
}

.icon-line-chart:before {
  content: '\e18c';
}

.icon-line-charts:before {
  content: '\e023';
}

.icon-list:before {
  content: '\e0a5';
}

.icon-loan:before {
  content: '\e0d6';
}

.icon-locate-me:before {
  content: '\e1b1';
}

.icon-locked:before {
  content: '\e153';
}

.icon-log:before {
  content: '\e022';
}

.icon-machine:before {
  content: '\e109';
}

.icon-manager-insight:before {
  content: '\e125';
}

.icon-manager:before {
  content: '\e0be';
}

.icon-map-2:before {
  content: '\e108';
}

.icon-map-3:before {
  content: '\e152';
}

.icon-map:before {
  content: '\e021';
}

.icon-marketing-campaign:before {
  content: '\e0b0';
}

.icon-master-task-triangle-2:before {
  content: '\e259';
}

.icon-master-task-triangle:before {
  content: '\e258';
}

.icon-meal:before {
  content: '\e0d5';
}

.icon-measure:before {
  content: '\e151';
}

.icon-measurement-document:before {
  content: '\e14f';
}

.icon-measuring-point:before {
  content: '\e150';
}

.icon-media-forward:before {
  content: '\e14a';
}

.icon-media-pause:before {
  content: '\e14c';
}

.icon-media-play:before {
  content: '\e14b';
}

.icon-media-reverse:before {
  content: '\e14d';
}

.icon-media-rewind:before {
  content: '\e14e';
}

.icon-meeting-room:before {
  content: '\e149';
}

.icon-menu2:before {
  content: '\e1de';
}

.icon-menu:before {
  content: '\e0da';
}

.icon-message-error:before {
  content: '\e0b1';
}

.icon-message-information:before {
  content: '\e202';
}

.icon-message-popup:before {
  content: '\e21c';
}

.icon-message-success:before {
  content: '\e203';
}

.icon-message-warning:before {
  content: '\e201';
}

.icon-microphone:before {
  content: '\e0f2';
}

.icon-mileage:before {
  content: '\e148';
}

.icon-minimize:before {
  content: '\e20f';
}

.icon-mirrored-task-circle-2:before {
  content: '\e25d';
}

.icon-mirrored-task-circle:before {
  content: '\e25c';
}

.icon-money-bills:before {
  content: '\e020';
}

.icon-monitor-payments:before {
  content: '\e107';
}

.icon-move:before {
  content: '\e1e8';
}

.icon-mri-scan:before {
  content: '\e10a';
}

.icon-multiple-bar-chart:before {
  content: '\e18b';
}

.icon-multiple-line-chart:before {
  content: '\e18a';
}

.icon-multiple-pie-chart:before {
  content: '\e190';
}

.icon-multiple-radar-chart:before {
  content: '\e187';
}

.icon-multiselect-all:before {
  content: '\e224';
}

.icon-multiselect-none:before {
  content: '\e225';
}

.icon-multiselect:before {
  content: '\e1f4';
}

.icon-my-sales-order:before {
  content: '\e0d4';
}

.icon-my-view:before {
  content: '\e0df';
}

.icon-nav-back:before {
  content: '\e1eb';
}

.icon-navigation-down-arrow:before {
  content: '\e1e2';
}

.icon-navigation-left-arrow:before {
  content: '\e067';
}

.icon-navigation-right-arrow:before {
  content: '\e066';
}

.icon-navigation-up-arrow:before {
  content: '\e1e1';
}

.icon-negative:before {
  content: '\e1dc';
}

.icon-Netweaver-business-client:before {
  content: '\e10f';
}

.icon-newspaper:before {
  content: '\e106';
}

.icon-notes:before {
  content: '\e01f';
}

.icon-notification-2:before {
  content: '\e0d3';
}

.icon-notification:before {
  content: '\e0b1';
}

.icon-number-sign:before {
  content: '\e0d2';
}

.icon-numbered-text:before {
  content: '\e237';
}

.icon-nurse:before {
  content: '\e146';
}

.icon-nutrition-activity:before {
  content: '\e105';
}

.icon-official-service:before {
  content: '\e103';
}

.icon-offsite-work:before {
  content: '\e104';
}

.icon-open-command-field:before {
  content: '\e1c0';
}

.icon-open-folder:before {
  content: '\e1c9';
}

.icon-opportunities:before {
  content: '\e01e';
}

.icon-opportunity:before {
  content: '\e01d';
}

.icon-order-status:before {
  content: '\e0d7';
}

.icon-org-chart:before {
  content: '\e0d1';
}

.icon-outbox:before {
  content: '\e212';
}

.icon-outdent:before {
  content: '\e267';
}

.icon-outgoing-call:before {
  content: '\e01c';
}

.icon-overflow:before {
  content: '\e1f2';
}

.icon-overlay:before {
  content: '\e0d0';
}

.icon-overview-chart:before {
  content: '\e0cf';
}

.icon-paging:before {
  content: '\e1b2';
}

.icon-paid-leave:before {
  content: '\e0cd';
}

.icon-palette:before {
  content: '\e145';
}

.icon-paper-plane:before {
  content: '\e0cc';
}

.icon-passenger-train:before {
  content: '\e08f';
}

.icon-past:before {
  content: '\e01b';
}

.icon-paste:before {
  content: '\e246';
}

.icon-pause:before {
  content: '\e09c';
}

.icon-payment-approval:before {
  content: '\e060';
}

.icon-pdf-attachment:before {
  content: '\e01a';
}

.icon-pdf-reader:before {
  content: '\e0ce';
}

.icon-pending:before {
  content: '\e019';
}

.icon-per-diem:before {
  content: '\e0cb';
}

.icon-performance:before {
  content: '\e0d9';
}

.icon-permission:before {
  content: '\e0b8';
}

.icon-person-placeholder:before {
  content: '\e0ca';
}

.icon-personnel-view:before {
  content: '\e1b6';
}

.icon-pharmacy:before {
  content: '\e102';
}

.icon-phone:before {
  content: '\e018';
}

.icon-photo-voltaic:before {
  content: '\e017';
}

.icon-physical-activity:before {
  content: '\e101';
}

.icon-picture:before {
  content: '\e016';
}

.icon-pie-chart:before {
  content: '\e015';
}

.icon-pipeline-analysis:before {
  content: '\e143';
}

.icon-pixelate:before {
  content: '\e273';
}

.icon-play:before {
  content: '\e09b';
}

.icon-pool:before {
  content: '\e014';
}

.icon-popup-window:before {
  content: '\e0c9';
}

.icon-positive:before {
  content: '\e1db';
}

.icon-post:before {
  content: '\e0ab';
}

.icon-ppt-attachment:before {
  content: '\e013';
}

.icon-present:before {
  content: '\e012';
}

.icon-primary-key:before {
  content: '\e278';
}

.icon-print:before {
  content: '\e099';
}

.icon-private:before {
  content: '\e0c8';
}

.icon-process:before {
  content: '\e0c7';
}

.icon-product:before {
  content: '\e011';
}

.icon-program-triangles-2:before {
  content: '\e25b';
}

.icon-program-triangles:before {
  content: '\e25a';
}

.icon-project-definition-triangle-2:before {
  content: '\e257';
}

.icon-project-definition-triangle:before {
  content: '\e256';
}

.icon-projector:before {
  content: '\e141';
}

.icon-provision:before {
  content: '\e140';
}

.icon-pull-down:before {
  content: '\e089';
}

.icon-pushpin-off:before {
  content: '\e0a0';
}

.icon-pushpin-on:before {
  content: '\e09f';
}

.icon-puzzle:before {
  content: '\e0c6';
}

.icon-quality-issue:before {
  content: '\e0e4';
}

.icon-question-mark:before {
  content: '\e090';
}

.icon-radar-chart:before {
  content: '\e188';
}

.icon-receipt:before {
  content: '\e0c5';
}

.icon-record:before {
  content: '\e09d';
}

.icon-redo:before {
  content: '\e1df';
}

.icon-refresh:before {
  content: '\e010';
}

.icon-repost:before {
  content: '\e13f';
}

.icon-request:before {
  content: '\e0c4';
}

.icon-reset:before {
  content: '\e249';
}

.icon-resize-corner:before {
  content: '\e24f';
}

.icon-resize-horizontal:before {
  content: '\e209';
}

.icon-resize-vertical:before {
  content: '\e20a';
}

.icon-resize:before {
  content: '\e1e7';
}

.icon-response:before {
  content: '\e09e';
}

.icon-responsive:before {
  content: '\e27c';
}

.icon-restart:before {
  content: '\e204';
}

.icon-retail-store-manager:before {
  content: '\e10e';
}

.icon-retail-store:before {
  content: '\e00f';
}

.icon-rhombus-milestone-2:before {
  content: '\e253';
}

.icon-rhombus-milestone:before {
  content: '\e252';
}

.icon-role:before {
  content: '\e0a9';
}

.icon-sales-document:before {
  content: '\e00e';
}

.icon-sales-notification:before {
  content: '\e147';
}

.icon-sales-order-item:before {
  content: '\e0c2';
}

.icon-sales-order:before {
  content: '\e0c3';
}

.icon-sales-quote:before {
  content: '\e13e';
}

.icon-sap-box:before {
  content: '\e124';
}

.icon-sap-logo-shape:before {
  content: '\e1bb';
}

.icon-sap-ui5:before {
  content: '\e21b';
}

.icon-save:before {
  content: '\e09a';
}

.icon-scatter-chart:before {
  content: '\e18f';
}

.icon-scissors:before {
  content: '\e226';
}

.icon-screen-split-one:before {
  content: '\e23f';
}

.icon-screen-split-three:before {
  content: '\e241';
}

.icon-screen-split-two:before {
  content: '\e240';
}

.icon-search:before {
  content: '\e00d';
}

.icon-settings:before {
  content: '\e00c';
}

.icon-share-2:before {
  content: '\e13d';
}

.icon-share:before {
  content: '\e0a7';
}

.icon-shelf:before {
  content: '\e1e5';
}

.icon-shield:before {
  content: '\e13c';
}

.icon-shipping-status:before {
  content: '\e0b3';
}

.icon-shortcut:before {
  content: '\e0b5';
}

.icon-show-edit:before {
  content: '\e1d8';
}

.icon-show:before {
  content: '\e1e9';
}

.icon-signature:before {
  content: '\e0c1';
}

.icon-simple-payment:before {
  content: '\e0c0';
}

.icon-simulate:before {
  content: '\e13b';
}

.icon-slim-arrow-down:before {
  content: '\e1ef';
}

.icon-slim-arrow-left:before {
  content: '\e1ee';
}

.icon-slim-arrow-right:before {
  content: '\e1ed';
}

.icon-slim-arrow-up:before {
  content: '\e1f0';
}

.icon-soccor:before {
  content: '\e100';
}

.icon-sonography:before {
  content: '\e0ff';
}

.icon-sort-ascending:before {
  content: '\e1fd';
}

.icon-sort-descending:before {
  content: '\e1fc';
}

.icon-sort:before {
  content: '\e095';
}

.icon-sorting-ranking:before {
  content: '\e1b7';
}

.icon-sound-loud:before {
  content: '\e228';
}

.icon-sound-off:before {
  content: '\e229';
}

.icon-sound:before {
  content: '\e227';
}

.icon-source-code:before {
  content: '\e244';
}

.icon-split:before {
  content: '\e286';
}

.icon-status-completed:before {
  content: '\e1ab';
}

.icon-status-critical:before {
  content: '\e1ae';
}

.icon-status-error:before {
  content: '\e1ac';
}

.icon-status-in-progress:before {
  content: '\e1ae';
}

.icon-status-inactive:before {
  content: '\e1ad';
}

.icon-status-negative:before {
  content: '\e1ac';
}

.icon-status-positive:before {
  content: '\e1ab';
}

.icon-step:before {
  content: '\e0fe';
}

.icon-stethoscope:before {
  content: '\e0fd';
}

.icon-stop:before {
  content: '\e205';
}

.icon-strikethrough:before {
  content: '\e27a';
}

.icon-study-leave:before {
  content: '\e0fc';
}

.icon-subway-train:before {
  content: '\e0fb';
}

.icon-suitcase:before {
  content: '\e13a';
}

.icon-supplier:before {
  content: '\e0b9';
}

.icon-survey:before {
  content: '\e00b';
}

.icon-switch-classes:before {
  content: '\e0bf';
}

.icon-switch-views:before {
  content: '\e0bc';
}

.icon-synchronize:before {
  content: '\e00a';
}

.icon-syntax:before {
  content: '\e139';
}

.icon-syringe:before {
  content: '\e138';
}

.icon-sys-add:before {
  content: '\e1f6';
}

.icon-sys-back-2:before {
  content: '\e1c6';
}

.icon-sys-back:before {
  content: '\e1c5';
}

.icon-sys-cancel-2:before {
  content: '\e1c8';
}

.icon-sys-cancel:before {
  content: '\e1c7';
}

.icon-sys-enter-2:before {
  content: '\e1c1';
}

.icon-sys-enter:before {
  content: '\e1c2';
}

.icon-sys-find-next:before {
  content: '\e1ca';
}

.icon-sys-find:before {
  content: '\e1cb';
}

.icon-sys-first-page:before {
  content: '\e1ce';
}

.icon-sys-help-2:before {
  content: '\e1c3';
}

.icon-sys-help:before {
  content: '\e1c4';
}

.icon-sys-last-page:before {
  content: '\e1d0';
}

.icon-sys-minus:before {
  content: '\e1f7';
}

.icon-sys-monitor:before {
  content: '\e1cc';
}

.icon-sys-next-page:before {
  content: '\e1cf';
}

.icon-sys-prev-page:before {
  content: '\e1cd';
}

.icon-system-exit-2:before {
  content: '\e1be';
}

.icon-system-exit:before {
  content: '\e1bd';
}

.icon-table-chart:before {
  content: '\e0bb';
}

.icon-table-column:before {
  content: '\e27f';
}

.icon-table-row:before {
  content: '\e27e';
}

.icon-table-view:before {
  content: '\e0ba';
}

.icon-tag-cloud-chart:before {
  content: '\e192';
}

.icon-tag:before {
  content: '\e137';
}

.icon-tags:before {
  content: '\e0e9';
}

.icon-target-group:before {
  content: '\e0af';
}

.icon-task:before {
  content: '\e009';
}

.icon-taxi:before {
  content: '\e0fa';
}

.icon-technical-object:before {
  content: '\e136';
}

.icon-temperature:before {
  content: '\e008';
}

.icon-text-align-center:before {
  content: '\e234';
}

.icon-text-align-justified:before {
  content: '\e232';
}

.icon-text-align-left:before {
  content: '\e233';
}

.icon-text-align-right:before {
  content: '\e235';
}

.icon-text-color:before {
  content: '\e276';
}

.icon-text-formatting:before {
  content: '\e22e';
}

.icon-text:before {
  content: '\e27b';
}

.icon-theater:before {
  content: '\e0f9';
}

.icon-thing-type:before {
  content: '\e135';
}

.icon-thumb-down:before {
  content: '\e223';
}

.icon-thumb-up:before {
  content: '\e222';
}

.icon-time-account:before {
  content: '\e0f8';
}

.icon-time-entry-request:before {
  content: '\e0a4';
}

.icon-time-overtime:before {
  content: '\e134';
}

.icon-timesheet:before {
  content: '\e0a3';
}

.icon-to-be-reviewed:before {
  content: '\e0e1';
}

.icon-toaster-down:before {
  content: '\e0f7';
}

.icon-toaster-top:before {
  content: '\e0f6';
}

.icon-toaster-up:before {
  content: '\e0f5';
}

.icon-tools-opportunity:before {
  content: '\e0b7';
}

.icon-touch:before {
  content: '\e282';
}

.icon-travel-expense-report:before {
  content: '\e132';
}

.icon-travel-expense:before {
  content: '\e007';
}

.icon-travel-itinerary:before {
  content: '\e133';
}

.icon-travel-request:before {
  content: '\e131';
}

.icon-tree:before {
  content: '\e0f4';
}

.icon-trend-down:before {
  content: '\e24b';
}

.icon-trend-up:before {
  content: '\e24a';
}

.icon-trip-report:before {
  content: '\e0f1';
}

.icon-two-keys:before {
  content: '\e279';
}

.icon-ui-notifications:before {
  content: '\e239';
}

.icon-umbrella:before {
  content: '\e130';
}

.icon-underline-text:before {
  content: '\e231';
}

.icon-undo:before {
  content: '\e1e0';
}

.icon-unfavorite:before {
  content: '\e0a1';
}

.icon-unlocked:before {
  content: '\e12f';
}

.icon-unpaid-leave:before {
  content: '\e0f3';
}

.icon-unwired:before {
  content: '\e10c';
}

.icon-up:before {
  content: '\e1e4';
}

.icon-upload-to-cloud:before {
  content: '\e219';
}

.icon-upload:before {
  content: '\e12e';
}

.icon-upstacked-chart:before {
  content: '\e0f0';
}

.icon-user-edit:before {
  content: '\e243';
}

.icon-user-settings:before {
  content: '\e22b';
}

.icon-validate:before {
  content: '\e280';
}

.icon-value-help:before {
  content: '\e1f3';
}

.icon-vds-file:before {
  content: '\e1ba';
}

.icon-vehicle-repair:before {
  content: '\e12d';
}

.icon-vertical-bar-chart-2:before {
  content: '\e181';
}

.icon-vertical-bar-chart:before {
  content: '\e0ef';
}

.icon-vertical-bullet-chart:before {
  content: '\e216';
}

.icon-vertical-grip:before {
  content: '\e1fa';
}

.icon-vertical-stacked-chart:before {
  content: '\e184';
}

.icon-vertical-waterfall-chart:before {
  content: '\e21f';
}

.icon-video:before {
  content: '\e006';
}

.icon-visits:before {
  content: '\e005';
}

.icon-waiver:before {
  content: '\e0ee';
}

.icon-wallet:before {
  content: '\e12c';
}

.icon-warning2:before {
  content: '\e0e2';
}

.icon-warning:before {
  content: '\e094';
}

.icon-washing-machine:before {
  content: '\e004';
}

.icon-weather-proofing:before {
  content: '\e05f';
}

.icon-web-cam:before {
  content: '\e0ed';
}

.icon-widgets:before {
  content: '\e115';
}

.icon-windows-doors:before {
  content: '\e003';
}

.icon-work-history:before {
  content: '\e0ea';
}

.icon-workflow-tasks:before {
  content: '\e0e5';
}

.icon-world:before {
  content: '\e091';
}

.icon-wounds-doc:before {
  content: '\e0ec';
}

.icon-wrench:before {
  content: '\e002';
}

.icon-write-new-document:before {
  content: '\e23c';
}

.icon-write-new:before {
  content: '\e23d';
}

.icon-x-ray:before {
  content: '\e0eb';
}

.icon-zoom-in:before {
  content: '\e1d5';
}

.icon-zoom-out:before {
  content: '\e1d6';
}
