@import '../../../assets/styles/variables/colors';

.blur-login {
  &__wrapper {
    background: $common-white;
  }

  &__description {
    text-align: center;
    margin-bottom: 20px;
    color: $troy-light-purple;
  }

  &__link {
    text-decoration: none;
  }
}
