@import '../../../assets/styles/index';

$circle-diam: 10px;
$common-border: 2px;
$line-color: #ededed;
$triangle-width: 10px;
$border-gray: 4px;
$main-padding: 20px;
$mobile-main-padding: 10px;

.timeline {
  &__wrapper {
    height: 450px;
    color: $common-black;
    margin: 15px 15px 100px 15px;
  }

  &__content {
    padding: 15px;

    &-blur-button {
      @include troy-button;
    }

    &-slider {
      top: -$common-border - calc($circle-diam / 2);
      &::before {
        content: '';
        display: block;
        position: absolute;
        transform: translateX(-50%) !important;
        left: 13px;
        width: 1px;
        height: 100%;
        background-color: $sap-light-gray;
      }

      & [class='slick-list'] {
        margin: 14px 0;
        height: 445px !important;
        pointer-events: none;
      }
    }

    &-slick-arrow {
      position: relative !important;
      left: 4px !important;
      transform: rotateZ(90deg) !important;

      &-prev {
        top: unset !important;
        left: 4px !important;
        padding-bottom: 20px !important;
        &::before {
          right: unset !important;
          content: '\e1e1' !important;
          font-family: 'SAP-icons' !important;
          font-style: normal !important;
          speak: none !important;
          color: $sap-active-color !important;
        }
      }

      &-next {
        top: unset !important;
        right: unset !important;
        left: 4px !important;
        &::before {
          right: unset !important;
          content: '\e1e2' !important;
          font-family: 'SAP-icons' !important;
          font-style: normal !important;
          speak: none !important;
          color: $sap-active-color !important;
          right: 0px !important;
        }
      }
    }

    &-content {
      border-top: none;
      width: unset;
      margin: unset;
      margin-top: 30px;
    }

    &-header {
      font-size: 1.2rem;
      font-weight: bolder;
    }

    &-header,
    &-footer {
      color: $troy-black;
      text-align: center;
      padding: 10px 0;
    }

    &-circle {
      background-color: $sap-light-gray;
      position: absolute;
      width: $circle-diam;
      height: $circle-diam;
      border-radius: 7px;
      margin-top: 17px;
      left: 13px;
      transform: translate(-50%);

      &_active {
        @extend .timeline__content-circle;
      }
    }

    &-item {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      margin: 0px 20px 10px 30px;
      width: unset !important;
      align-items: center;
      position: relative;
      padding-top: $circle-diam;
      text-align: center;
      outline: none;
      background: $common-white;
      border: $common-border solid $common-dark-gray;
      height: 30px;

      &::after,
      &::before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &::after {
        border-right-color: $common-white;
        border-width: 7px;
        margin-top: -7px;
      }
      &::before {
        border-right-color: $common-dark-gray;
        border-width: 10px;
        margin-top: -10px;
      }

      &_active {
        @extend .timeline__content-item;
        background: $sap-active-color;
        color: $common-white !important;

        &::after {
          border-right-color: $sap-active-color;
          border-width: 7px;
          margin-top: -7px;
        }
      }

      &-datetime-wrapper {
        display: flex;
        box-sizing: border-box;
        color: $common-dark-gray;
        position: relative;
        margin: 0;
        width: 100%;
        font-size: 12px;
        justify-content: space-between;
        text-align: center;
        margin-left: 5px;

        &-image {
          max-height: 20px;
          max-width: 50px;
          transform: translateX(-50%);
        }

        &_active {
          @extend .timeline__content-item-datetime-wrapper;
          color: $common-white;
        }
      }
    }
  }
}
