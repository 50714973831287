@import './assets/styles/index';

html {
  // disabled because it's only available on Android and Mac
  // hyphens: auto;
}

body {
  color: #313131;
  font-family: 'CustomFont-Regular', sans-serif;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  @include mobile {
    width: 100%;
  }
}

.iti-mobile .intl-tel-input.iti-container {
  left: 16px !important;
  right: 16px !important;
  z-index: 1400;
  border: 1px solid #313131;
  box-shadow: 0 0 8px rgba(43, 21, 60, 0.2);
  border-radius: 16px;

  .country-list {
    box-shadow: none;
    border: none;
    padding: 12px 0;
  }
}

.country-list {
  border-radius: 16px;

  .divider {
    display: none;
  }

  .country {
    margin: 4px 0;
    padding: 4px 8px 4px 5px !important;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.highlight {
      background: #e6e6e6;
    }

    .flag-box {
      flex-shrink: 0;
      margin-left: 4px;
      margin-right: 18px !important;
      position: relative;
      align-self: center;
      width: 12px !important;
      height: 12px;
      padding: 0;
      border-radius: 100%;
      overflow: hidden;
      transform: scale(1.666666666666);

      .iti-flag {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .dial-code {
      font-family: 'CustomFont-Regular', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: normal;
      width: 54px;
      flex-shrink: 0;
      color: #666666 !important;
    }

    .country-name {
      font-family: 'CustomFont-Regular', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: normal;
      order: 3;
      margin-left: 8px;
      margin-right: 0 !important;
      color: #313131;
      white-space: normal;
    }
  }
}

.pg-viewer-wrapper {
  overflow-y: auto;
}

.pdf-viewer-container,
.pdf-viewer,
.pdf-canvas {
  height: 100%;
}

.pdf-canvas > canvas {
  height: 100% !important;
  width: 100% !important;
}

.agent-signature {
  max-height: 48px;
}

.embedded-chat {
  &_hide {
    & .embeddedServiceHelpButton {
      display: none;
    }
  }

  &_blur {
    & .helpButton {
      @include blur;
    }
  }
}
