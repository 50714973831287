@import '../../../assets/styles/mixins';

.blurred {
  &__wrapper {
    position: relative;
  }

  &__blur {
    @include blur;
  }

  &__content {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    @include mobile {
      width: 100%;
    }
    @include desktop {
      min-width: 200px;
      width: auto;
    }
  }

  &__mock-data {
    width: 100%;
    height: 100%;
  }
}
