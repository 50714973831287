$xs-screen: 0px;
$xls-screen: 400px;
$sm-screen: 600px;
$md-screen: 960px;
$lg-screen: 1280px;
$xl-screen: 1920px;

@mixin xs-up {
  @media only screen and (min-width: $xs-screen) {
    @content;
  }
}

@mixin xls-up {
  @media only screen and (min-width: $xls-screen) {
    @content;
  }
}

@mixin sm-up {
  @media only screen and (min-width: $sm-screen) {
    @content;
  }
}

@mixin md-up {
  @media only screen and (min-width: $md-screen) {
    @content;
  }
}

@mixin lg-up {
  @media only screen and (min-width: $lg-screen) {
    @content;
  }
}

@mixin xl-up {
  @media only screen and (min-width: $xl-screen) {
    @content;
  }
}

@mixin xs-down {
  @media only screen and (max-width: $xs-screen) {
    @content;
  }
}

@mixin xls-down {
  @media only screen and (max-width: $xls-screen) {
    @content;
  }
}

@mixin sm-down {
  @media only screen and (max-width: $sm-screen - 1) {
    @content;
  }
}

@mixin md-down {
  @media only screen and (max-width: $md-screen) {
    @content;
  }
}

@mixin lg-down {
  @media only screen and (max-width: $lg-screen) {
    @content;
  }
}

@mixin xl-down {
  @media only screen and (max-width: $xl-screen) {
    @content;
  }
}

@mixin low-height {
  @media only screen and (max-height: 600px) {
    @content;
  }
}

@mixin hidden-xs-up {
  @include xs-up {
    display: none !important;
  }
}
@mixin hidden-xs-down {
  @include xs-down {
    display: none !important;
  }
}

@mixin hidden-sm-up {
  @include sm-up {
    display: none !important;
  }
}
@mixin hidden-sm-down {
  @include sm-down {
    display: none !important;
  }
}

@mixin hidden-md-up {
  @include md-up {
    display: none !important;
  }
}
@mixin hidden-md-down {
  @include md-down {
    display: none !important;
  }
}

@mixin hidden-lg-up {
  @include lg-up {
    display: none !important;
  }
}
@mixin hidden-lg-down {
  @include lg-down {
    display: none !important;
  }
}

@mixin hidden-xl-up {
  @include xl-up {
    display: none !important;
  }
}
@mixin hidden-xl-down {
  @include xl-down {
    display: none !important;
  }
}

@mixin mobile {
  @include sm-down {
    @content;
  }
}

@mixin desktop {
  @include sm-up {
    @content;
  }
}

@mixin only-mobile {
  @include hidden-sm-up;
  @content;
}

@mixin only-desktop {
  @include hidden-sm-down;
  @content;
}

$mobile-width-limit: $sm-screen;
$tablet-width-limit: $md-screen;
:export {
  mobileWidth: $mobile-width-limit;
  tabletWidth: $tablet-width-limit;
}
