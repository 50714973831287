@import '../../assets/styles/index';

.sap-iframe {
  &__wrapper {
    font-family: '72-Light' !important;
    font-size: 0.8125rem !important;
    display: flex;
    flex-direction: row;
    height: initial !important;
    background-size: 100%;
    background: $sap-background;
    min-height: 100%;

    &-grid-container {
      overflow: scroll;
    }
  }

  &__tabs {
    &-tab {
      text-transform: none !important;
      color: $common-black !important;
      font-family: '72-Light' !important;
      font-size: 1rem !important;
      letter-spacing: 0 !important;
    }

    &-selected {
      color: $sap-active-color !important;
    }

    &-indicator {
      color: $sap-active-color !important;
      background: $sap-active-color !important;

      &-sticky {
        @extend .sap-iframe__tabs-indicator;

        @include md-down {
          position: absolute;
          margin-top: 0px;
          height: 2px !important;
        }

        z-index: 110 !important;
        top: 66px !important;
      }
    }

    &-container {
      background: $sap-global-light;

      &-sticky {
        @include md-down {
          position: relative;
          width: 100%;
          z-index: 99;
        }

        @extend .sap-iframe__tabs-container;

        position: fixed;
        width: 100%;
        z-index: 99;
      }
    }
  }

  &__divider {
    position: fixed;
    left: 33%;
    top: 0;
    bottom: 0;
    border-left: 1px solid black;
    margin-left: 4px;
    margin-right: 4px;
    z-index: 105;

    @include md-down {
      visibility: hidden;
    }

    &-horizontal {
      display: block;
      border: 0;
      border-top: 1px solid $sap-divider-color;
      padding: 0;

      &-sticky {
        @extend .sap-iframe__divider-horizontal;

        @include md-down {
          width: 100%;
          position: relative;
          margin-top: 0px;
        }

        width: 67%;
        position: fixed;
        z-index: 100;
        margin-top: 23px;
      }
    }
  }
}
