@import '../../assets/styles/index';

.document {
  &__wrapper {
    background: $troy-gray;
    font-size: 1.1rem;
    padding-top: 1rem;
    padding-bottom: 3rem;

    a {
      color: $troy-light-purple;
      text-decoration: none;

      &:hover {
        color: $troy-dark-purple;
        text-decoration: underline;
      }
    }
  }

  &__content {
    &-wrapper {
      @extend %main-troy-indent;
      background: $troy-white;
      padding: 4rem;
    }
  }

  &__section {
    &-header {
      margin: 0;
      margin-bottom: 0.5rem;
      font-size: 1.6rem;
    }

    p {
      margin: 0;
      margin-bottom: 1rem;
    }
  }
}
