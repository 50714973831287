.contact-box {
  &__wrapper {
    font-size: 1.1rem;
    line-height: 1.3;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__pic {
    max-width: 100%;
  }

  &__header {
    font-size: 1.2rem;
    font-weight: bolder;
  }

  &__header,
  &__text,
  &__link {
    text-align: center;
  }

  &__text {
    &-wrapper {
      padding: 0 20px;
    }
  }
}
