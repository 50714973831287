@import '../mixins/screens';

%main-troy-indent {
  margin: auto;

  @include xls-up {
    max-width: $xls-screen;
  }

  @include sm-up {
    max-width: $sm-screen;
  }

  @include md-up {
    max-width: $md-screen;
  }
}
