@import '../../assets/styles/mixins/screens';

.main-layout {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > :nth-child(2) {
      flex: 1 0 auto;
      min-height: 695px; // because of NVI certification in footer
    }

    @include mobile {
      &-mobile-padding {
        padding-bottom: 64px;
      }
    }
  }
}
