@import '../../assets/styles/variables/colors';

.payment {
  &-success {
    &__content {
      background: $common-white;
      padding: 10px;
      text-align: center;
      white-space: pre-line;

      & > :not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
